/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import { createStyles, Grid, Table, Text, Group, NativeSelect, Rating, Pagination, Paper, Center } from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getFeedbackList } from '../utils/util';
import { AsyncAutocomplete, useMedplum } from '@medplum/react';
import { IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import { buildGraphQLQuery, getResourcesFromResponse, HeaderSearchTypes, SearchGraphQLResponse, toKey, toOption } from '../../../react/src/AppShell/HeaderSearchInput';
import { DateInput } from '@mantine/dates';


const useStyles = createStyles(() => ({
  tr: {
    cursor: 'pointer',
  },
  rightCorner: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px',
  },
}));

function Feedback() {
  const { classes } = useStyles();
  const [activity, setActivity] = useState<string>('Rating');
  const medplum = useMedplum();
  const [feedbackData, setFeedbackData] = useState<any[]>([]);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [resourceData, setResourceData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const skipEffect = useRef(false);

  type SearchType = 'patient' | 'practitioner' | 'date';
  const [searchType, setSearchType] = useState<SearchType>('patient');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const fetchFeedback = useCallback(async (id: string | null, startDate?: string, endDate?: string) => {
    try {
      const data = await getFeedbackList(medplum, activity, offset, id || '', searchType, startDate, endDate);
      setResourceData(data);
      setFeedbackData(data.entry || []);
    } catch (error) {
      console.error('Error fetching feedback:', error);
      setFeedbackData([]);
    }
  }, [activity, medplum, offset, selectedId, searchType]);

  useEffect(() => {
    if (skipEffect.current) {
      skipEffect.current = false;
      return;
    }
    fetchFeedback(selectedId);
  }, [activity, medplum, offset, selectedId, fetchFeedback]);

  const renderValue = (data: any, field: string) => {
    const resource = data.resource;
    switch (field) {
      case 'practitionerName':
        return resource?.subject?.display || '-';
      case 'patientName':
        const patientNameExtension = resource?.extension?.find((ext: any) => ext.url === '/intg/structure/extensions/patientName');
        return patientNameExtension?.valueString || '-';
      case 'session':
        const session = resource?.extension?.find((ext: any) => ext.url === '/intg/structure/extensions/sessionName');
        return session?.valueString || '-';
      case 'noteType':
        const noteTypeExtension = resource?.extension?.find((ext: any) => ext.url === 'http://note-type');
        return noteTypeExtension?.valueString || '-';
      case 'dateTime':
        return formatDateTime(resource?.authoredOn) || '-';
      case 'rating':
        return (
          <Rating
            value={Number(resource?.category?.[0].coding?.[0].display)}
            size="xs"
            readOnly
          />
        );
      case 'viewSession':
        const redirectLinkId = resource?.extension?.find((ext: any) => ext.url === '/intg/structure/extensions/sessionId');
        const sessionId = redirectLinkId?.valueString;
        return sessionId ? (
          <a href={`Appointment/${sessionId}/details`} target="_blank" rel="noopener noreferrer">
            <img src="../../../img/icons/eye.svg" />
          </a>
        ) : '-';
      default:
        return '-';
    }
  };
  const formatDateTime = (dateTimeString: string) => {
    if (!dateTimeString) {
      return '-';
    }

    const date = new Date(dateTimeString);

    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = (`0${date.getMinutes()}`).slice(-2);

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12;
    const formattedHours = (`0${hours}`).slice(-2);

    return `${month}-${day}-${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const fields = ['practitionerName', 'patientName', 'session', 'noteType', 'rating', 'dateTime', 'viewSession'];

  // Add this function for loading search data
  const loadData = useCallback(
    async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
      if (searchType === 'date') return []; 
      const query = buildGraphQLQuery(input, searchType, 20);
      const options = { signal };
      const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
      return getResourcesFromResponse(response, input, 20);
    },
    [medplum, searchType]
  );

  const handleFeedbackSearch = useCallback(
    async (item: HeaderSearchTypes[]): Promise<void> => {
      const id = item.length > 0 ? item[0].id ?? null : null;
      setSelectedId(id);
      skipEffect.current = true;
      fetchFeedback(id);
    },
    [fetchFeedback]
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);

    if (date) {
      const startTime = '00:01:00.000Z';
      const endTime = '23:59:00.000Z';

      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);

      const startDateString = `${year}-${month}-${day}T${startTime}`;
      const endDateString = `${year}-${month}-${day}T${endTime}`;

      fetchFeedback(null, startDateString, endDateString);
    }
  };

  return (
    <Paper shadow="xs" m="lg" p="xs">
      <Group spacing={2} style={{ justifyContent: 'space-between' }}>
        <Group spacing={2}>
          <NativeSelect
            label="Search by"
            data={[
              { value: 'patient', label: 'Patient' },
              { value: 'practitioner', label: 'Practitioner' },
              //{ value: 'date', label: 'Search by Date' },
            ]}
            value={searchType}
            onChange={(event) => setSearchType(event.currentTarget.value as SearchType)}
            ml={20}
            mr={20}
            mb={24}
            styles={{
              label: { marginBottom: '8px', marginLeft: '5px' },
            }}
          />
          {searchType === 'date' ? (
            <DateInput
              placeholder="Select date"
              value={selectedDate}
              onChange={handleDateChange}

              valueFormat="MM-DD-YYYY"
            />
          ) : (
            <AsyncAutocomplete
              key={`${activity}?${offset}`}
              size="sm"
              radius="md"
              icon={<IconSearch size={16} color='#3176C9' />}
              placeholder={searchType === 'patient' ? 'Search Patient' : searchType === 'practitioner' ? 'Search Practitioner' : searchType === 'date' ? 'Search Date' : ''}
              toKey={toKey}
              toOption={toOption}
              onChange={handleFeedbackSearch}
              loadOptions={loadData}
              maxSelectedValues={0}
              clearSearchOnChange
              clearable={true}
              mt={5}
              sx={{
                input: {
                  width: '200px',
                  paddingTop: '6px',
                  marginTop: '-3px',
                  transition: 'none',
                  '&:focus-within': {
                    width: '200px',
                  },
                },
              }}
            />
          )}
        </Group>
        <NativeSelect
          data={[
            { value: 'Rating', label: 'All Rating' },
            { value: '1', label: '1 Star' },
            { value: '2', label: '2 Stars' },
            { value: '3', label: '3 Stars' },
            { value: '4', label: '4 Stars' },
            { value: '5', label: '5 Stars' },
          ]}
          value={activity}
          onChange={(event) => setActivity(event.currentTarget.value)}
          mb="md"
          m={20}
        />
      </Group>
      <Grid>
        <Grid.Col lg={12} >
          <Table className='feedback_table'>
            <thead>
              <tr>
                {fields.map((field, index) => (
                  <th key={index}>
                    <Group position="apart" noWrap>
                      <Text weight={500} size="sm">
                        {
                          field === 'practitionerName' ? 'Practitioner Name' :
                            field === 'patientName' ? 'Patient Name' :
                              field === 'session' ? 'Session' :
                                field === 'session' ? 'Session' :
                                  field === 'noteType' ? 'Note Type' :
                                    field === 'dateTime' ? 'Date & Time' :
                                      field === 'viewSession' ? 'View Session' :
                                        field === 'rating' ? 'Rating' : ''}
                      </Text>
                    </Group>
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {feedbackData && feedbackData.length > 0 ? feedbackData.map((resource: any, index: number) => (
                <React.Fragment key={index}>
                  <tr key={`row-${index}`} className={`${classes.tr} ${expandedRow === index ? 'bg-color-class' : ''}`}
                    onClick={() => setExpandedRow(expandedRow === index ? null : index)}>
                    {fields.map((field, i) => (
                      <td key={`cell-${index}-${i}`} style={{ padding: '1rem' }}>{renderValue(resource, field)}</td>
                    ))}
                    <td key={`expand-icon-${index}`}>
                      {resource?.resource?.note?.[0]?.text ? (
                        expandedRow === index ? <IconChevronUp className="h-5 w-5" /> : <IconChevronDown className="h-5 w-5" />
                      ) : (
                        <div className="h-5 w-5" />
                      )}
                    </td>
                  </tr>
                  {expandedRow === index && resource?.resource?.note?.[0]?.text && (
                    <tr key={`expanded-${index}`}>
                      <td colSpan={fields.length} style={{ padding: '1rem' }} >
                        {/* Put the expanded content here */}
                        <div style={{ marginBottom: '5px' }}>
                          <span style={{ color: '#000', fontWeight: 500 }}>Feedback:</span>
                          <span style={{ paddingLeft: '5px' }}>{resource?.resource?.note?.[0]?.text?.split(':')?.[0] || '-'}</span>
                        </div>
                        <div>
                          <span style={{ color: '#000', fontWeight: 500 }}>Comment:</span>
                          <span style={{ paddingLeft: '5px' }}>{resource?.resource?.note?.[0]?.text?.split(':')?.[1] || '-'}</span>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )) : <tr><td>No data available</td></tr>}
            </tbody>
          </Table>
          {resourceData?.total > 0 && (
            <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
              <Pagination
                className="pagination"
                value={currentPage}
                total={Math.ceil(resourceData?.total / 20)}
                onChange={(page) => {
                  setCurrentPage(page);
                  setOffset((page - 1) * 20);
                }}
                getControlProps={(control) => {
                  switch (control) {
                    case 'previous':
                      return { 'aria-label': 'Previous page' };
                    case 'next':
                      return { 'aria-label': 'Next page' };
                    default:
                      return {};
                  }
                }}
              />
            </Center>
          )}
        </Grid.Col>
      </Grid>
    </Paper>
  );
}

export default Feedback;

