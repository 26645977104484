/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  ActionIcon,
  Button,
  Center,
  createStyles,
  Group,
  Loader,
  Menu,
  NativeSelect,
  Pagination,
  Table,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  DEFAULT_SEARCH_COUNT,
  Filter,
  formatHumanName,
  formatSearchQuery,
  SearchRequest,
  SortRule,
} from '@medplum/core';
import {
  Bundle,
  HumanName,
  OperationOutcome,
  Resource,
  ResourceType,
  SearchParameter,
  UserConfiguration,
} from '@medplum/fhirtypes';
import {
  IconAdjustmentsHorizontal,
  IconEdit,
  IconFilter,
  IconMicrophone,
  IconRefresh,
  IconSearch,
  IconTemplate,
  IconTransitionBottom,
  IconTransitionTop,
  IconTrash,
  IconUserCircle,
  IconX,
} from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from '../../../react/src/Container/Container';
import { useMedplum } from '@medplum/react-hooks';
import { SearchExportDialog } from '../../../react/src/SearchExportDialog/SearchExportDialog';
import { SearchFieldEditor } from '../../../react/src/SearchFieldEditor/SearchFieldEditor';
import { SearchFilterEditor } from '../../../react/src/SearchFilterEditor/SearchFilterEditor';
import { SearchFilterValueDialog } from '../../../react/src/SearchFilterValueDialog/SearchFilterValueDialog';
import { SearchPopupMenu } from '../../../react/src/SearchPopupMenu/SearchPopupMenu';
import { isCheckboxCell, killEvent } from '../../../react/src/utils/dom';
import { getFieldDefinitions } from '../../../react/src/SearchControl/SearchControlField';
import {
  addFilter,
  buildFieldNameString,
  clearFiltersOnField,
  renderValue,
  setPage,
} from '../../../react/src/SearchControl/SearchUtils';
import { useNavigate } from 'react-router-dom';
import { PropertyDisplayMapping } from '../../../react/src/constants';
import { deletePatientResponse, deletePractitionerResponse, getPatientList, getPatientSessionsResp, inviteUserByAdmin, deleteSessionAndNotes, archivePatientResponse, getPractitionerList, archivePractitionerResponse, archiveSessionResponse } from '../utils/util';

import ConfirmBox from '../patients/ConfirmBox';
import { toast } from 'react-toastify';
import InvitePopup from './InvitePopup';
import { buildGraphQLQuery, getResourcesFromResponse, HeaderSearchTypes, SearchGraphQLResponse, toKey, toOption } from '../../../react/src/AppShell/HeaderSearchInput';
import { AsyncAutocomplete } from '@medplum/react';
import { useAppContext } from '../AppProvider';

export const practitionerMap: Record<string, string> = {
  "prescriber": "Psychiatry",
  "therapist": "Counselor",
  "psychological testing": "Psychological Testing"
};

export class SearchChangeEvent extends Event {
  readonly definition: SearchRequest;

  constructor(definition: SearchRequest) {
    super('change');
    this.definition = definition;
  }
}

export class SearchLoadEvent extends Event {
  readonly response: Bundle;

  constructor(response: Bundle) {
    super('load');
    this.response = response;
  }
}

export class SearchClickEvent extends Event {
  readonly resource: Resource;
  readonly browserEvent: React.MouseEvent;

  constructor(resource: Resource, browserEvent: React.MouseEvent) {
    super('click');
    this.resource = resource;
    this.browserEvent = browserEvent;
  }
}


export interface SearchControlProps {
  searchParams?: URLSearchParams;
  pathname?: string;
  headerSearchDisabled?: boolean;
  search: SearchRequest;
  userConfig?: UserConfiguration;
  checkboxesEnabled?: boolean;
  hideToolbar?: boolean;
  hideFilters?: boolean;
  onLoad?: (e: SearchLoadEvent) => void;
  onChange?: (e: SearchChangeEvent) => void;
  onClick?: (e: SearchClickEvent) => void;
  onAuxClick?: (e: SearchClickEvent) => void;
  onNew?: () => void;
  onExport?: () => void;
  onExportCsv?: () => void;
  onExportTransactionBundle?: () => void;
  onDelete?: (ids: string[]) => void;
  onPatch?: (ids: string[]) => void;
  onBulk?: (ids: string[]) => void;
}

interface SearchControlState {
  searchResponse?: Bundle;
  selected: { [id: string]: boolean };
  fieldEditorVisible: boolean;
  filterEditorVisible: boolean;
  filterDialogVisible: boolean;
  exportDialogVisible: boolean;
  filterDialogFilter?: Filter;
  filterDialogSearchParam?: SearchParameter;
}

interface PractitionerDetails {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  withNPI?: boolean;
}

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  tr: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

/**
 * The SearchControl component represents the embeddable search table control.
 * It includes the table, rows, headers, sorting, etc.
 * It does not include the field editor, filter editor, pagination buttons.
 * @param props - The SearchControl React props.
 * @returns The SearchControl React node.
 */
export function SearchControl(props: SearchControlProps): JSX.Element {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const [schemaLoaded, setSchemaLoaded] = useState(false);
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const { search, onLoad } = props;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [isInvitePopupOpen, setInvitePopupOpen] = useState(false);
  const [actionType, setActionType] = useState<'delete' | 'archive' | 'Unarchive' | null>(null);
  const patientTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const practitionerTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { sessionStatus, setSessionStatus, practitionerActiveStatus, setPractitionerActiveStatus, patientActiveStatus, setPatientActiveStatus } = useAppContext();

  const handleChange = (event: { target: { value: string; }; }) => {
    const selectedStatus = event.target.value === 'Active' ? 'true' : 'false';
    setPatientActiveStatus(selectedStatus);
  };

  const handlePractitionerChange = (event: { target: { value: string; }; }) => {
    const selectedStatus = event.target.value === 'Active' ? 'true' : 'false';
    setPractitionerActiveStatus(selectedStatus);
  }

  const handleSessionChange = (event: { target: { value: string; }; }) => {
    const selectedStatus = event.target.value === 'Active' ? 'checked-in' : 'cancelled';
    setSessionStatus(selectedStatus);
  };

  useEffect(() => {
    if (props.search.resourceType === 'Appointment') {
      loadPatientSessionResults();
    }
  }, [sessionStatus, search.count, search.offset]);

  useEffect(() => {
    if (props.search.resourceType === 'Patient') {
      loadPatientResults();
    }
  }, [patientActiveStatus, search.count, search.offset]);

  useEffect(() => {
    if (props.search.resourceType != 'Patient' && props.search.resourceType != 'Appointment' && props.search.resourceType != 'Practitioner') {
      loadResults();
    }
  }, [search.count, search.offset]);

  useEffect(() => {
    if (props.search.resourceType === 'Practitioner') {
      loadPractitionerResults();
    }
  }, [practitionerActiveStatus, search.count, search.offset]);

  const openPopup = () => {
    setInvitePopupOpen(true);
  };

  const closePopup = () => {
    setInvitePopupOpen(false);
  };

  const handleInviteSubmit = async (medplum: any, details: PractitionerDetails) => {
    try {
      const response: any = await inviteUserByAdmin(medplum, details);

      if (response) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        toast.error(`Failed to send invitation. Please try again later.`, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error: any) {
      console.error('Error:', error);
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      closePopup();
    }
  };



  const [state, setState] = useState<SearchControlState>({
    selected: {},
    fieldEditorVisible: false,
    filterEditorVisible: false,
    exportDialogVisible: false,
    filterDialogVisible: false,
  });

  const stateRef = useRef<SearchControlState>(state);
  stateRef.current = state;

  const totalType = search.total ?? 'accurate';
  const loadResults = useCallback(
    (options?: RequestInit) => {
      setOutcome(undefined);
      medplum
        .search(
          search.resourceType as ResourceType,
          formatSearchQuery({ ...search, total: totalType, fields: undefined }),
          options
        )
        .then((response) => {
          setState({ ...stateRef.current, searchResponse: response });
          if (onLoad) {
            onLoad(new SearchLoadEvent(response));
          }
        })
        .catch((reason) => {
          setState({ ...stateRef.current, searchResponse: undefined });
          setOutcome(reason);
        });
    },
    [medplum, search, totalType, onLoad]
  );

  //calling custom API to get the patient details
  const loadPatientResults = useCallback(
    (patientName?: string) => {
      setOutcome(undefined);
      getPatientList(medplum, search.count, patientName ? 0 : search.offset, search, patientActiveStatus, patientName)
        .then((response) => {
          setState({ ...stateRef.current, searchResponse: response?.data });
          if (onLoad) {
            onLoad(new SearchLoadEvent(response?.data));
          }
        })
        .catch((reason) => {
          setState({ ...stateRef.current, searchResponse: undefined });
          setOutcome(reason);
        });
    },
    [medplum, search, totalType, onLoad, patientActiveStatus]
  );

  const patientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (patientTimeoutRef.current) {
      clearTimeout(patientTimeoutRef.current);
    }

    patientTimeoutRef.current = setTimeout(() => {
      if (value.length >= 3 || value.length === 0) {
        loadPatientResults(value);
      }
    }, 1000);
  };

  const clearPatientSearch = () => {
    setSearchTerm('');
    loadPatientResults(''); 
  };

  const handleAppointmentSearch = useCallback(
    (item: HeaderSearchTypes[]): void => {
      if (item.length > 0) {
        loadPatientSessionResults(item[0].id);
      } else {
        loadPatientSessionResults();
      }
    },
    []
  );

  const practitionerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (practitionerTimeoutRef.current) {
      clearTimeout(practitionerTimeoutRef.current);
    }

    practitionerTimeoutRef.current = setTimeout(() => {
      if (value.length >= 3 || value.length === 0) {
        loadPractitionerResults(value);
      }
    }, 1000);
  };

  //calling custom API to get the practitioner details
  const loadPractitionerResults = useCallback(
    (practitionerName?: string) => {
      setOutcome(undefined);
      getPractitionerList(medplum, search.count, practitionerName ? 0 : search.offset, search, practitionerActiveStatus, practitionerName)
        .then((response) => {
          setState({ ...stateRef.current, searchResponse: response?.data });
          if (onLoad) {
            onLoad(new SearchLoadEvent(response?.data));
          }
        })
        .catch((reason) => {
          setState({ ...stateRef.current, searchResponse: undefined });
          setOutcome(reason);
        });
    },
    [medplum, search, totalType, onLoad, practitionerActiveStatus]
  );

  //calling custom API to get the patient session details
  const loadPatientSessionResults = useCallback(
    (patientId?: string) => {
      setOutcome(undefined);
      getPatientSessionsResp(medplum, search.count, search.offset, search, '', sessionStatus, patientId)
        .then((response) => {
          setState({ ...stateRef.current, searchResponse: response?.data });
          if (onLoad) {
            onLoad(new SearchLoadEvent(response?.data));
          }
        })
        .catch((reason) => {
          setState({ ...stateRef.current, searchResponse: undefined });
          setOutcome(reason);
        });
    },
    [medplum, search, totalType, onLoad, sessionStatus]
  );

  const refreshResults = useCallback(() => {
    setState({ ...stateRef.current, searchResponse: undefined });
    props.search.resourceType === 'Patient' ? loadPatientResults() : props.search.resourceType === 'Appointment' ? loadPatientSessionResults() : loadResults({ cache: 'reload' });
  }, [loadResults]);

  const loadData = useCallback(
    async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
      const query = buildGraphQLQuery(input, 'patient', 10);
      const options = { signal };
      const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
      return getResourcesFromResponse(response, input);
    },
    [medplum]
  );

  const [deletingUserName, setDeletingUserName] = useState('');

  const [showConfirmBox, setShowConfirmBox] = useState(false);

  const [deletingUserId, setDeletingUserId] = useState<string>('');

  const [deletingResourceId, setDeletingResourceId] = useState<string>('');

  const [isDeletingLoader, setIsDeletingLoader] = useState(false);

  interface ResourceDetails {
    id: string;
    name: { family: any; given: any[]; }[];
  }

  const handleDeleteIconClick = (resource: ResourceDetails) => {
    const name = resource?.name?.[0];
    const sessionName = 'Session';
    if ((name?.given || name?.family) && resource.id) {
      const userName = `${name?.given || ''} ${name?.family || ''} `;
      setShowConfirmBox(true);
      setDeletingUserName(userName);
      setDeletingUserId(resource.id);
    } else if (resourceType === 'Appointment' && resource.id) {
      setShowConfirmBox(true);
      setDeletingUserName(sessionName);
      setDeletingResourceId(resource.id);
    } else {
      console.error('Invalid resource data:', resource);
    }
  };

  const handleConfirmDelete = async () => {
    setActionType('delete');
    setIsDeletingLoader(true);
    try {
      let deleteResponse;
      let id;
      if (resourceType === 'Practitioner') {
        deleteResponse = deletePractitionerResponse;
        id = deletingUserId;
      } else if (resourceType === 'Patient') {
        deleteResponse = deletePatientResponse;
        id = deletingUserId;
      } else if (resourceType === 'Appointment') {
        deleteResponse = deleteSessionAndNotes;
        id = deletingResourceId;
      }

      if (deleteResponse && id) {
        deleteResponse(medplum, id)
          .then((response) => {
            if (response) {
              toast.success(`${deletingUserName} deleted successfully!`, {
                position: toast.POSITION.TOP_RIGHT
              });
              setIsDeletingLoader(false);
              setShowConfirmBox(false);
              resourceType === 'Appointment' ? loadPatientSessionResults() : resourceType === 'Patient' ? loadPatientResults() : resourceType === "Practitioner" ? loadPractitionerResults() : loadResults({ cache: 'reload' });
            }
          })
          .catch((error: any) => {
            console.error('Error fetching data:', error);
            toast.error(`Failed to delete ` + (resourceType === 'Appointment' ? 'Session' : resourceType), {
              position: toast.POSITION.TOP_RIGHT
            });
            setIsDeletingLoader(false);
          });
      }
    } catch (error) {
      console.error(`Error deleting ${resourceType}:`, error);
      toast.error(`Failed to delete the user`, {
        position: toast.POSITION.TOP_RIGHT
      });
      setIsDeletingLoader(false); // Stop the loader
    }
  };

  /**
   * Emits a change event to the optional change listener.
   * @param newSearch - The new search definition.
   */
  function emitSearchChange(newSearch: SearchRequest): void {
    if (props.onChange) {
      newSearch.filters?.forEach((filter) => {
        if (filter.value === '') {
          filter.value = 'false';
        }
      });
      props.onChange(new SearchChangeEvent(newSearch));
    }
  }

  /**
   * Handles a click on a order row.
   * @param e - The click event.
   * @param resource - The FHIR resource.
   */
  function handleRowClick(e: React.MouseEvent, resource: Resource): void {
    if (isCheckboxCell(e.target as Element)) {
      // Ignore clicks on checkboxes
      return;
    }

    if (e.button === 2) {
      // Ignore right clicks
      return;
    }

    killEvent(e);

    const isAux = e.button === 1 || e.ctrlKey || e.metaKey;

    if (!isAux && props.onClick) {
      props.onClick(new SearchClickEvent(resource, e));
    }

    if (isAux && props.onAuxClick) {
      props.onAuxClick(new SearchClickEvent(resource, e));
    }
  }

  useEffect(() => {
    setSchemaLoaded(false);
    medplum
      .requestSchema(props.search.resourceType as ResourceType)
      .then(() => setSchemaLoaded(true))
      .catch(console.log);
  }, [medplum, props.search.resourceType]);

  if (!schemaLoaded) {
    return (
      <Center style={{ width: '100%', height: '100%' }}>
        <Loader />
      </Center>
    );
  }

  const fields = getFieldDefinitions(search);
  const resourceType = search.resourceType;
  const lastResult = state.searchResponse;
  const entries = lastResult?.entry;
  const resources = entries?.map((e) => e.resource);

  const buttonVariant = 'subtle';
  const buttonColor = 'gray';
  const iconSize = 20;

  const onEdit = (resourceId: any) => {
    const path = (resourceType === 'Appointment' || resourceType === "Practitioner") ? 'details' : 'edit';
    navigate(`/${resourceType}/${resourceId}/${path}`);
  };

  function getDisplayTitle(key: any): string {
    if (PropertyDisplayMapping.hasOwnProperty(key)) {
      return PropertyDisplayMapping[key];
    } else {
      return buildFieldNameString(key);
    }
  }

  fields?.map((field: any) => {
    if (field.name === 'lastSession') {
      field['searchParams'] = [
        {
          resourceType: 'SearchParameter',
          base: ['Resource'],
          code: `_json_extension_0_valueDateTime`,
          name: 'lastSession',
          type: 'date',
          expression: 'Resource.extension?.[0]?.valueDateTime',
        },
      ];
    }
  });

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '' || /^\s+$/.test(inputValue)) {
      setSearchTerm('');
    } else {
      setSearchTerm(inputValue);
      event.target.style.border = '0.0625rem solid #ced4da';
      event.target.style.width = '220px';
    }
  };

  const filteredResources = resources?.filter((resource: any) => {
    if (searchTerm) {
      const fullName = resource.name?.map((nameObj: any) => nameObj?.given?.join(' '))?.join(' ');
      return fullName?.toLowerCase().includes(searchTerm?.toLowerCase());
    }
    return true;
  });

  const resetFilters = () => {
    // Reset the search filters and fields in the props
    if (props.search) {
      props.search.filters = [];
      getFieldDefinitions(props.search)?.forEach((field: any) => {
        onClear(field?.searchParams?.[0]);
      });
    }
  };

  const handleClearAll = () => {
    resetFilters();
    setSearchTerm('');
    getDefaultSortRules(resourceType);
  };

  function getDefaultSortRules(resourceType: string): SortRule[] {
    const lastSearch = getLastSearch(resourceType);
    if (lastSearch?.sortRules) {
      lastSearch.sortRules = [];
      return lastSearch.sortRules;
    }
    return [{ code: '_lastUpdated', descending: true }];
  }

  function getLastSearch(resourceType: string): SearchRequest | undefined {
    const value = localStorage.getItem(resourceType + '-defaultSearch');
    return value ? (JSON.parse(value) as SearchRequest) : undefined;
  }

  function onClear(searchParam: SearchParameter): void {
    clearFiltersOnField(props.search, searchParam?.code as string);
  }

  const disabledFilter = () => {
    const filters = props.search.filters?.length;
    const sourtRule = props.search.sortRules?.length && props.search.sortRules[0].code !== '_lastUpdated';
    if (filters || sourtRule || searchTerm) {
      return false;
    }
    return true;
  };

  const getPatientDisplayName = (resource: any): string | null => {
    const patientEntry = resource.participant?.find(
      (participant: any) => participant.actor.reference?.includes('Patient')
    );
    return patientEntry ? patientEntry.actor.display || "-" : "-";
  };

  const handleIconClick = () => {
    setSearchTerm('');
  };

  const handleArchivePatientClick = (resource: any) => {
    setDeletingUserId(resource.id);
    setShowConfirmBox(true);
  };

  const handleArchivePractitionerClick = (resource: any) => {
    setDeletingUserId(resource.id);
    setShowConfirmBox(true);
  };

  const handlePractitionerSessionArchive = (resource: any) => {
    setDeletingUserId(resource.id);
    setShowConfirmBox(true);
  };

  //practtitioenr archive implementation
  const archivePractitionerImplementaion = () => {
    setIsDeletingLoader(true);
    if (deletingUserId) {
      const payload = {
        practitionerId: deletingUserId,
      }
      archivePractitionerResponse(medplum, payload)
        .then((response) => {
          if (response) {
            const name = response.name?.[0];
            const givenName = name?.given?.[0] || '';
            toast.success(`${givenName} ${actionType === 'archive' ? 'has been archived' : 'has been unarchived'} successfully!`, {
              position: toast.POSITION.TOP_RIGHT
            });
            setIsDeletingLoader(false);
            setShowConfirmBox(false);
            props.search.resourceType === 'Practitioner' ? loadPractitionerResults() : loadResults({ cache: 'reload' });
          }
        })
        .catch((error: any) => {
          toast.error(`Error while archiving:!`, {
            position: toast.POSITION.TOP_RIGHT
          });
          console.error('Error while archiving:', error);
          setIsDeletingLoader(false);
          setShowConfirmBox(false);
        });
    }
  }

 //patient archive implementation
  const archivePatientImplementaion = () => {
    setIsDeletingLoader(true);
    if (deletingUserId) {
      const payload = {
        patientId: deletingUserId,
      }
      archivePatientResponse(medplum, payload)
        .then((response) => {
          if (response) {
            const name = response.name?.[0];
            const givenName = name?.given?.[0] || '';
            toast.success(`${givenName} ${actionType === 'archive' ? 'has been archived' : 'has been unarchived'} successfully!`, {
              position: toast.POSITION.TOP_RIGHT
            });
            setIsDeletingLoader(false);
            setShowConfirmBox(false);
            props.search.resourceType === 'Patient' ? loadPatientResults() : loadResults({ cache: 'reload' });
          }
        })
        .catch((error: any) => {
          toast.error(`Error while archiving:!`, {
            position: toast.POSITION.TOP_RIGHT
          });
          console.error('Error while archiving:', error);
          setIsDeletingLoader(false);
          setShowConfirmBox(false);
        });
    }
  }

  //session archive implementation
  const archiveSessionImplementaion = () => {
    setIsDeletingLoader(true);
    if (deletingUserId) {
      const payload = {
        sessionId: deletingUserId,
      }
      archiveSessionResponse(medplum, payload)
        .then((response) => {
          if (response) {
            toast.success(`Session ${actionType === 'archive' ? 'has been deleted' : 'has been unarchived'} successfully!`, {
              position: toast.POSITION.TOP_RIGHT
            });
            setIsDeletingLoader(false);
            setShowConfirmBox(false);
            props.search.resourceType === 'Appointment' ? loadPatientSessionResults() : loadResults({ cache: 'reload' });
          }
        })
        .catch((error: any) => {
          toast.error(`Error while archiving:!`, {
            position: toast.POSITION.TOP_RIGHT
          });
          console.error('Error while archiving:', error);
          setIsDeletingLoader(false);
          setShowConfirmBox(false);
        });
    }
  }

  const entityType = resourceType === 'Appointment'
    ? 'session'
    : resourceType === 'Practitioner'
      ? 'practitioner'
      : 'patient';

  return (
    <div className={classes.root} data-testid="search-control">
      {!props.hideToolbar && (
        <Group position="apart" mb="xl" className="filters">
          <Group spacing={2}>
            {props.onNew && resourceType === 'Composition' && (
              <Button
                className="filters-action"
                compact
                variant={buttonVariant}
                color={buttonColor}
                leftIcon={<IconTemplate size={18} />}
                onClick={props.onNew}
                h={35}
              >
                New
              </Button>
            )}

            {resourceType === 'Patient' && (
              <Group className='searchIcon'>
                <TextInput
                  placeholder="Search Patient"
                  value={searchTerm}
                  onChange={patientSearch}
                  icon={<IconSearch size="1rem" />}
                  rightSection={
                    searchTerm && (
                      <IconX
                        stroke={2}
                        size={16}
                        onClick={clearPatientSearch}
                      />
                    )
                  }
                  styles={() => ({
                    input: {
                      borderColor: '#ced4da',
                      '&:focus': {
                        borderColor: '#228be6',
                      },
                    },
                  })}
                />
              </Group>
            )}

            {medplum.isSuperAdmin() && resourceType === 'Practitioner' && (
              <Group className='searchIcon'>
                <TextInput
                  placeholder="Search Practitioner"
                  value={searchTerm}
                  onChange={practitionerSearch}
                  icon={<IconSearch size="1rem" />}
                  rightSection={
                    searchTerm && (
                      <IconX
                        stroke={2}
                        size={16}
                        onClick={() => setSearchTerm('')}
                      />
                    )
                  }
                  styles={() => ({
                    input: {
                      borderColor: '#ced4da',
                      '&:focus': {
                        borderColor: '#228be6',
                      },
                    },
                  })}
                />
              </Group>
            )}

            {resourceType === 'Appointment' && (
              <Group className='header-search'>
                <AsyncAutocomplete
                  key={`${props.pathname}?${props.searchParams}`}
                  size="sm"
                  radius="md"
                  icon={<IconSearch size={16} color='#3176C9' />}
                  placeholder={'Search Patient'}
                  toKey={toKey}
                  toOption={toOption}
                  onChange={handleAppointmentSearch}
                  loadOptions={loadData}
                  maxSelectedValues={0}
                  clearSearchOnChange
                  clearable={true}
                  sx={{
                    input: {
                      width: '200px', // Default width
                      paddingTop: '6px',
                      marginTop: '-3px',
                      transition: 'none', // No transition if patient is selected
                      '&:focus-within': {
                        width: '200px'
                      },
                    },
                  }}
                />
              </Group>
            )}

            {medplum.isSuperAdmin() && (resourceType === 'Patient' || resourceType === 'Practitioner' || resourceType === 'Appointment') && (
              <>
                {/* <Button
                  compact
                  variant={buttonVariant}
                  color={buttonColor}
                  leftIcon={<img src="/img/icons/filter-square.svg" className="filter-icons" />}
                  onClick={() => setState({ ...stateRef.current, filterEditorVisible: true })}
                >
                  Filters
                </Button> */}
                {/* {!isMobile && isExportPassed() && (
                  <Button
                    compact
                    variant={buttonVariant}
                    color={buttonColor}
                    leftIcon={<img src="/img/icons/export.svg" className="filter-icons" />}
                    onClick={
                      props.onExport ? props.onExport : () => setState({ ...stateRef.current, exportDialogVisible: true })
                    }
                    style={{ display: 'none' }}
                  >
                    Export...
                  </Button>
                )} */}
              </>
            )}
            {resourceType === 'Practitioner' && (
              <>
                <Button
                  className="filters-action"
                  variant={buttonVariant}
                  color={buttonColor}
                  leftIcon={<IconTemplate size={18} />}
                  h={35}
                  onClick={openPopup}
                >
                  Invite New Practitioner
                </Button>

                <InvitePopup
                  isOpen={isInvitePopupOpen}
                  onClose={closePopup}
                  onSubmit={(details) => handleInviteSubmit(medplum, details)}
                  header="Invitation Details"
                  submitButtonLabel="Send Invitation"
                  cancelButtonLabel="Cancel"
                  isLoading={false}
                />
              </>
            )}

            {resourceType === 'Basic' && (
              <>
                <TextInput
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  icon={<IconSearch size="1rem" />}

                  rightSection={
                    searchTerm && (
                      <IconX
                        stroke={2}
                        size={16}
                        color='#3176C9'
                        style={{ cursor: 'pointer' }}
                        onClick={handleIconClick}
                      />
                    )
                  }
                />

                <Tooltip label="Clear Filter" withArrow>
                  <Button
                    ml="lg"
                    className="clear-filter"
                    disabled={disabledFilter()}
                    onClick={() => {
                      handleClearAll();
                      const url = `/Patient?_count=20&_fields=name,birthDate&_offset=${search?.offset}&_sort=-_lastUpdated&active=${patientActiveStatus}`;
                      navigate(url);
                    }}
                  >
                    <IconFilter />
                  </Button>
                </Tooltip>
              </>
            )}
          </Group>
          <Group spacing={2}>
            {medplum.isSuperAdmin() && resourceType === 'Patient' && (
              <NativeSelect
                data={['Active', 'Inactive']}
                value={patientActiveStatus === 'true' ? 'Active' : 'Inactive'}
                onChange={handleChange}
                withAsterisk
                rightSectionWidth={50}
                mr={10}
                size="md"
              />
            )}
            {medplum.isSuperAdmin() && resourceType === 'Practitioner' && (
              <NativeSelect
                data={['Active', 'Inactive']}
                value={practitionerActiveStatus === 'true' ? 'Active' : 'Inactive'}
                onChange={handlePractitionerChange}
                withAsterisk
                rightSectionWidth={50}
                mr={10}
                size="md"
              />
            )}
            {medplum.isSuperAdmin() && resourceType === 'Appointment' && (
              <NativeSelect
                data={['Active', 'Inactive']}
                value={sessionStatus === 'checked-in' ? 'Active' : 'Inactive'}
                onChange={handleSessionChange}
                withAsterisk
                rightSectionWidth={50}
                mr={10}
                size="md"
              />
            )}
            {lastResult && (
              <Center style={{ alignItems: 'end' }}>
                <Text size="xs" color="dimmed" mr="xs">
                  Showing {getStart(search, lastResult.total as number)}-{getEnd(search, lastResult.total as number)} of{' '}
                  {lastResult.total?.toLocaleString()}
                </Text>
                <ActionIcon title="Refresh" onClick={refreshResults}>
                  <IconRefresh size={iconSize} />
                </ActionIcon>
              </Center>
            )}
          </Group>
        </Group>
      )}
      <Table>
        <thead>
          <tr>
            {fields.map((field, index) => {
              if (
                ((field.name === 'tokensUsed' || field.name === 'tokenUsageCost') &&
                resourceType === 'Appointment' &&
                !medplum.isSuperAdmin()) || (field.name === 'practitioner' && !medplum.isSuperAdmin())
              ) {
                return null;
              }
              return (
                <th
                  key={index}
                  style={{
                    width: `${field.name === 'sessionDescription'
                      ? '275px'
                      : field.name === 'start'
                        ? '300px'
                        : field.name === 'name'
                          ? '200px'
                          : field.name === 'practitionerType'
                            ? '250px'
                            : field.name === 'tokenUsageCost'
                              ? '200px'
                              : field.name === 'tokensUsed'
                                ? '200px'
                                 : field.name === 'practitioner'
                                ? '200px'
                                 : field.name === 'patient'
                              ? '200px'
                                : 'auto'
                      }`,
                  }}
                >
                  <Menu shadow="md" width={240} position="bottom-end">
                    <Menu.Target>
                      <UnstyledButton className={classes.control}>
                        <Group position="apart" noWrap>
                          <Text weight={500} size="sm">
                            {field.name === 'start' && resourceType === 'Appointment'
                              ? 'Session Date & Time'
                              : field.name === 'start' && resourceType === 'Patient'
                                ? 'Last Session Date & Time'
                                : field.name === 'active' && resourceType === 'Practitioner'
                                  ? 'Account Status'
                                  : field.name === 'tokensUsed' && resourceType === 'Appointment' && medplum.isSuperAdmin()
                                    ? 'Tokens Used'
                                    : field.name === 'tokensUsed' && resourceType === 'Appointment' && !medplum.isSuperAdmin()
                                      ? null
                                      : field.name === 'tokenUsageCost' &&
                                        resourceType === 'Appointment' &&
                                        medplum.isSuperAdmin()
                                        ? 'Tokens/Cost'
                                        : field.name === 'tokenUsageCost' &&
                                          resourceType === 'Appointment' &&
                                          !medplum.isSuperAdmin()
                                          ? null
                                          : getDisplayTitle(field.name)}
                          </Text>
                          <Center className={classes.icon}>
                            {(field.name === 'tokensUsed' || field.name === 'tokenUsageCost') &&
                              resourceType === 'Appointment' &&
                              !medplum.isSuperAdmin() ? (
                              ''
                            ) : field.name === 'sessionDescription' ||  field.name === 'tokenUsageCost' || field.name === 'practitionerType' || (field.name === 'practitioner' &&  !medplum.isSuperAdmin()) ? (
                              ''
                            ) : (
                              <IconAdjustmentsHorizontal size={14} stroke={1.5} />
                            )}
                          </Center>
                        </Group>
                      </UnstyledButton>
                    </Menu.Target>
                    <SearchPopupMenu
                      search={props.search}
                      searchParams={field.searchParams}
                      onPrompt={(searchParam, filter) => {
                        setState({
                          ...stateRef.current,
                          filterDialogVisible: true,
                          filterDialogSearchParam: searchParam,
                          filterDialogFilter: filter,
                        });
                      }}
                      onChange={(result) => {
                        emitSearchChange(result);
                      }}
                    />
                  </Menu>
                </th>
              );
            })}
            {medplum.isSuperAdmin() && resourceType === 'Practitioner' ? <th className='blackColor'>Subcription End date</th> : null}
            {medplum.isSuperAdmin() && resourceType === 'Patient' ? <th className='blackColor'>Provider Name</th> : null}
            <th className="actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredResources?.map(
            (resource: any, index: number) =>
              resource && (
                <tr
                  key={index}
                  className={classes.tr}
                  data-testid="search-control-row"
                  onClick={(e) => handleRowClick(e, resource)}
                  onAuxClick={(e) => handleRowClick(e, resource)}
                >
                  {fields?.map((field, i: number) => {
                    if (
                      (field.name === 'tokensUsed' || field.name === 'tokenUsageCost') &&
                      resourceType === 'Appointment' &&
                      !medplum.isSuperAdmin()
                    ) {
                      return null;
                    }

                    const role = resource?.extension?.[0]?.valueString || '';
                    let mappedRole = practitionerMap[role.toLowerCase()] || role;
                    if (field.name === 'practitionerType' && resourceType === 'Practitioner') {
                      return (
                        <td key={i}>
                          {mappedRole}
                        </td>
                      );
                    }

                    return field.name === 'name' ? (
                      <td key={i}>
                        <Center style={{ justifyContent: 'flex-start', gap: '10px' }}>
                          {resourceType === 'Patient' && (
                            <Center className="profile-avatar">
                              {(resource?.name?.[0] &&
                                formatHumanName(resource?.name?.[0] as HumanName)
                                  ?.split(' ')
                                  ?.map((n) => n[0])
                                  ?.join('')) || <IconUserCircle size={20} />}
                            </Center>
                          )}
                          {renderValue(resource, field)}
                        </Center>
                      </td>
                    ) : field.name === 'lastSession' ? (

                      <td key={i}>
                        {resource?.extension?.length && (() => {
                          const latestAppointmentExt = resource.extension.find((ext: any) => ext.url === "/intg/structure/extensions/patientLatestAppointment");
                          const endDateExt = resource.extension.find((ext: any) => ext.url === "/intg/structure/extensions/patientLatestAppointment/endDate");

                          if (latestAppointmentExt?.valueDateTime) {
                            const parseDate = (dateString: any) => {
                              // Ensure the date string is in ISO format
                              const date = new Date(dateString);
                              return isNaN(date.getTime()) ? new Date(dateString.replace(/-/g, '/')) : date;
                            };

                            const startDate = parseDate(latestAppointmentExt.valueDateTime);
                            const endDate = endDateExt?.valueDateTime ? parseDate(endDateExt.valueDateTime) : null;

                            return (
                              <>
                                {startDate.toLocaleDateString()}
                                <br />
                                {endDate ? `${startDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })} 
                                - ${endDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}` : null}
                              </>
                            );
                          } else {
                            return ' ';
                          }
                        })()}

                      </td>
                    ) : field.name === 'patient' && resourceType === 'Appointment' ? (
                      <td key={i}>{getPatientDisplayName(resource)}</td>
                    ) : field.name === 'birthDate' ? (
                      <td key={i}>
                        {resource?.birthDate
                          ? resource?.birthDate
                          : ''}
                      </td>

                    ) : field.name === 'email' && resourceType === 'Practitioner' ? (
                      <td key={i}>
                        {resource?.telecom?.find((item: any) => item.system === 'email')?.value}
                      </td>
                    ) : field.name === 'active' && resourceType === 'Practitioner' ? (
                      <td key={i}>
                        {resource?.active === true ? 'Active' : resource?.active === false ? 'Inactive' : ''}
                      </td>
                    ) : field.name === 'sessionDescription' &&
                      (resourceType === 'Patient' || resourceType === 'Appointment') ? (
                      <td key={i}>{resource?.sessionDescription}</td>
                    ) : field.name === 'tokenUsageCost' && resourceType === 'Appointment' ? (
                      <td key={i}>
                        {resource?.numberOfTokens} / {resource?.indicativeCost === 0 ? '0' : resource?.indicativeCost ? `$${Number(resource?.indicativeCost).toFixed(5)}` : null}
                      </td>
                    ) : field.name === 'tokensUsed' && resourceType === 'Appointment' ? (
                      <td key={i}>{resource?.numberOfTokens}</td>
                    ) : field.name === 'start' && resourceType === 'Appointment' ? (
                      <td key={i}>{resource?.start}</td>
                    ):  field.name === 'practitioner' && resourceType === 'Appointment' ? (
                      medplum.isSuperAdmin() ? (
                        <td key={i}>
                          {resource?.participant?.find((participant: any) => participant.actor.reference?.includes('Practitioner'))?.actor.display || resource.meta.author.display}
                        </td>
                      ) : null
                    ) : (
                      <td key={field.name}>{renderValue(resource, field)}</td>
                    );
                  })}

                  {medplum.isSuperAdmin() && resourceType === 'Practitioner' ? (
                    <td>
                      {(() => {
                        const expiryExtension = resource?.extension?.find((ext: { url: string; }) => ext.url === 'http://plan-expiry-date');
                        if (expiryExtension?.valueString) {
                          const date = new Date(expiryExtension.valueString);
                          const day = date.getDate();
                          const month = date.toLocaleString('en-US', { month: 'long' });
                          const year = date.getFullYear();
                          return `${day} ${month}, ${year}`;
                        }
                        return null;
                      })()}
                    </td>
                  ) : null}

                  {medplum.isSuperAdmin() && resourceType === 'Patient' ? <td>{resource?.generalPractitioner?.[0]?.display}</td> : null}
                  <td className="actions">
                    <Tooltip
                      label="Edit Profile"
                      withArrow
                      position="top"
                      sx={{ fontSize: '12px', marginTop: '5px' }}
                    >
                      <IconEdit
                        size={18}
                        height={30}
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(resource.id);
                        }}
                      />
                    </Tooltip>
                    {resourceType === 'Patient' && (
                      <Tooltip
                        label="Start Recording"
                        withArrow
                        position="top"
                        sx={{ fontSize: '12px', marginTop: '1px' }}
                      >
                        <IconMicrophone
                          size={18}
                          onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/Patient/${resource.id}/session`);
                          }}
                        />
                      </Tooltip>
                    )}

                    {!medplum.isSuperAdmin() && resourceType == 'Appointment' && (
                      //session delete icon
                      // <IconTrash onClick={(event) => {
                      //   event.stopPropagation();
                      //   setActionType('delete');
                      //   handleDeleteIconClick(resource);
                      // }} />

                      //session archive icon
                      <IconTrash
                        onClick={(event) => {
                          event.stopPropagation();
                          setActionType('archive');
                          handlePractitionerSessionArchive(resource);
                        }} />
                    )}

                    {medplum.isSuperAdmin() && (resourceType === 'Practitioner' || resourceType === 'Patient' || resourceType === 'Appointment') ? (
                      <>
                        <IconTrash onClick={(event) => {
                          event.stopPropagation();
                          setActionType('delete');
                          handleDeleteIconClick(resource);
                        }} />

                        {/* Archive and unarchive icons for practitioner admin login */}
                        {resourceType === 'Practitioner' && (
                          <Tooltip
                            label={resource.active ? "Archive the practitioner record to make it inactive." : "Unarchive the practitioner record to make it active."}
                            withArrow
                            position="top"
                            sx={{ fontSize: '12px', marginTop: '5px' }}
                          >
                            {resource.active ? (
                              <IconTransitionBottom
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setActionType('archive');
                                  handleArchivePractitionerClick(resource);
                                }}
                              />
                            ) : (
                              <IconTransitionTop
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setActionType('Unarchive');
                                  handleArchivePractitionerClick(resource);
                                }}
                              />
                            )}
                          </Tooltip>
                        )}

                        {/*  unarchive icons for admin login */}
                        {sessionStatus === "cancelled" && resourceType === 'Appointment' && (
                          <Tooltip
                            label={"Unarchive the session record to make it active."}
                            withArrow
                            position="top"
                            sx={{ fontSize: '12px', marginTop: '5px' }}
                          >
                            <div>
                              <IconTransitionTop
                                style={{ marginTop: '8px' }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setActionType('Unarchive');
                                  handlePractitionerSessionArchive(resource);
                                }}
                              />
                            </div>
                          </Tooltip>
                        )}

                        {resource.active === false && resourceType === 'Patient' && (
                          <IconTransitionTop onClick={(event) => {
                            event.stopPropagation();
                            setActionType('Unarchive');
                            handleArchivePatientClick(resource);
                          }} />
                        )}
                      </>
                    ) : (
                      resourceType === 'Patient' && (
                        <Tooltip
                          label="Archive the patient record to make it as inactive."
                          withArrow
                          position="top"
                          sx={{ fontSize: '12px', marginTop: '5px' }}
                        >
                          <IconTransitionBottom
                            onClick={(event) => {
                              event.stopPropagation();
                              setActionType('archive');
                              handleArchivePatientClick(resource);
                            }}
                          />
                        </Tooltip>
                      )
                    )}

                  </td>
                </tr>
              )
          )}

          <ConfirmBox
            isOpen={showConfirmBox}
            onClose={() => setShowConfirmBox(false)}
            onConfirm={() => {
              if (actionType === 'delete') {
                handleConfirmDelete();
              }
              else if (actionType !== null && ['archive', 'Unarchive'].includes(actionType)) {
                if (medplum.isSuperAdmin() && resourceType === 'Practitioner') {
                  archivePractitionerImplementaion();
                } else if (resourceType === 'Patient') {
                  archivePatientImplementaion();
                } else if (resourceType === 'Appointment') {
                  archiveSessionImplementaion();
                }

              }
            }}
            patient={''}
            confirm={
              actionType === 'delete'
                ? "Delete"
                : (!medplum.isSuperAdmin() && actionType === 'archive' && resourceType === 'Appointment')
                  ? "Delete"
                  : (actionType === 'archive' && !medplum.isSuperAdmin() && resourceType === 'Patient')
                    ? "Archive"
                    : actionType === 'archive'
                      ? "Archive"
                      : "Unarchive"
            }
            cancel="Cancel"
            message={`Are you sure you want to ${actionType === 'delete'
                ? `delete ${deletingUserName}`
                : actionType === 'archive' && !medplum.isSuperAdmin() && resourceType === 'Appointment'
                  ? `delete this ${entityType}`
                  : actionType === 'archive'
                    ? `archive this ${entityType}`
                    : `unarchive this ${entityType}`
              }?`}
            additionalText=""
            isLoader={isDeletingLoader}
          />

        </tbody>
      </Table>
      {((resources?.length === 0) || (searchTerm && filteredResources && !filteredResources.length)) && (
        <Container>
          <Center style={{ height: 150 }}>
            <Text size="xl" color="dimmed">
              {resources?.length === 0 ? 'No results' : 'No results found'}
            </Text>
          </Center>
        </Container>
      )}

      {lastResult?.total !== undefined && lastResult.total > 0 && (
        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          <Pagination
            className="pagination"
            value={getPage(search)}
            total={
              searchTerm && filteredResources && filteredResources.length >= 0
                ? getTotalPages(search, lastResult.total, filteredResources)
                : getTotalPages(search, lastResult.total)
            }
            onChange={(newPage) => emitSearchChange(setPage(search, newPage))}
            getControlProps={(control) => {
              switch (control) {
                case 'previous':
                  return { 'aria-label': 'Previous page' };
                case 'next':
                  return { 'aria-label': 'Next page' };
                default:
                  return {};
              }
            }}
          />
        </Center>
      )}

      {outcome && (
        <div data-testid="search-error">
          <pre style={{ textAlign: 'left' }}>{JSON.stringify(outcome, undefined, 2)}</pre>
        </div>
      )}
      <SearchFieldEditor
        search={props.search}
        visible={stateRef.current.fieldEditorVisible}
        onOk={(result) => {
          emitSearchChange(result);
          setState({
            ...stateRef.current,
            fieldEditorVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            fieldEditorVisible: false,
          });
        }}
      />
      <SearchFilterEditor
        search={props.search}
        visible={stateRef.current.filterEditorVisible}
        onOk={(result) => {
          emitSearchChange(result);
          setState({
            ...stateRef.current,
            filterEditorVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            filterEditorVisible: false,
          });
        }}
      />
      <SearchExportDialog
        visible={stateRef.current.exportDialogVisible}
        exportCsv={props.onExportCsv}
        exportTransactionBundle={props.onExportTransactionBundle}
        onCancel={() => {
          setState({
            ...stateRef.current,
            exportDialogVisible: false,
          });
        }}
      />
      <SearchFilterValueDialog
        key={state.filterDialogSearchParam?.code}
        visible={stateRef.current.filterDialogVisible}
        title={state.filterDialogSearchParam?.code ? buildFieldNameString(state.filterDialogSearchParam.code) : ''}
        resourceType={resourceType}
        searchParam={state.filterDialogSearchParam}
        filter={state.filterDialogFilter}
        defaultValue=""
        onOk={(filter) => {
          emitSearchChange(addFilter(props.search, filter.code, filter.operator, filter.value));
          setState({
            ...stateRef.current,
            filterDialogVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            filterDialogVisible: false,
          });
        }}
      />
    </div>
  );
}

export const MemoizedSearchControl = React.memo(SearchControl);

// interface FilterDescriptionProps {
//   readonly resourceType: string;
//   readonly searchParams: SearchParameter[];
//   readonly filters?: Filter[];
// }

// function FilterDescription(props: FilterDescriptionProps): JSX.Element {
//   const filters = (props.filters ?? []).filter((f) => props.searchParams.find((p) => p.code === f.code));
//   if (filters.length === 0) {
//     return <span>no filters</span>;
//   }

//   return (
//     <>
//       {filters.map((filter: Filter) => (
//         <div key={`filter-${filter.code}-${filter.operator}-${filter.value}`}>
//           {getOpString(filter.operator)}
//           &nbsp;
//           <SearchFilterValueDisplay resourceType={props.resourceType} filter={filter} />
//         </div>
//       ))}
//     </>
//   );
// }

function getPage(search: SearchRequest): number {
  return Math.floor((search.offset ?? 0) / (search.count ?? DEFAULT_SEARCH_COUNT)) + 1;
}

function getTotalPages(search: SearchRequest, total: number, filteredResources?: any[]): number {
  const pageSize = search.count ?? DEFAULT_SEARCH_COUNT;

  if (filteredResources && filteredResources.length >= 0) {
    return Math.ceil(filteredResources.length / pageSize);
  } else {
    return Math.ceil(total / pageSize);
  }
}

function getStart(search: SearchRequest, total: number): number {
  return Math.min(total, (search.offset ?? 0) + 1);
}

function getEnd(search: SearchRequest, total: number): number {
  return Math.min(total, ((search.offset ?? 0) + 1) * (search.count ?? DEFAULT_SEARCH_COUNT));
}

