import { Button, Group, Loader, Modal, PasswordInput, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import React, { useState } from 'react';
import { getZoomToken } from '../utils/util';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { useAppContext } from '../AppProvider';

interface ZoomModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsZoomSession: (value: boolean) => void;
  setSessionInfo: React.Dispatch<React.SetStateAction<{
    accessToken?: string;
    sessionName?: string;
    patientSessionLink?: string;
  }>>;
  sessionInfo: {
    accessToken?: string;
    sessionName?: string;
    patientSessionLink?: string;
  };
}

const ZoomModal = ({ isOpen, onClose, setIsZoomSession, setSessionInfo, sessionInfo }: ZoomModalProps): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [patientName, setPatientName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const medplum = useMedplum();
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const [copied, setCopied] = useState<string | null>(null);
  const practitionerId = parts?.[1];
  const {setZoomPatientName} = useAppContext();

  const handleCreate = async (e: React.FormEvent): Promise<void> => {
    setIsLoading(true);
    e.preventDefault();
    const payload = {
      practitionerId: practitionerId,
      patientEmail: email,
      patientName: patientName,
      sessionPassword: password,
    };
    setZoomPatientName(patientName);
    await getZoomToken(payload, medplum)
      .then((response) => {
        showNotification({ color: 'green', message: 'Invitation sent to patient' });
        setSessionInfo({
          accessToken: response?.accessToken,
          sessionName: response?.sessionName,
          patientSessionLink: response?.patientSessionLink,
        });
        setTimeout(() => {}, 1000);
        setIsZoomSession(true);
      })
      .catch((error) => {
        console.error(error);
        showNotification({ color: 'red', message: 'Error Creating Session' });
      });

    setIsLoading(false);
  };

  const copyToClipboard = async (text: string, type: string): Promise<void> => {
    await navigator.clipboard.writeText(text).then(() => {
      setCopied(type);
      setTimeout(() => setCopied(null), 600);
    });
  };

  const handleClose = (): void => {
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={
        <Text size={'20px'} weight={600}>
          {sessionInfo.patientSessionLink ? 'Session Details' : 'Create a Zoom Meeting'}
        </Text>
      }
      size="md"
    >
      {sessionInfo.patientSessionLink && sessionInfo.sessionName ? (
        <Stack spacing="md" mih={'30vh'}>
          <Text size="sm" color="dimmed" mb="md">
          Your patient's virtual consultation session has been created. Please provide them with the following information to join if they haven't received the email:
          </Text>
          <Stack spacing="md">
            <TextInput label="Session ID" value={sessionInfo.sessionName} readOnly 
            sx={{
              input: {
                backgroundColor: '#F4F4F4',
                border: 'none'

              },
            }}
            />
            <TextInput
              label="Patient's Session URL"
              value={sessionInfo.patientSessionLink}
              readOnly
              rightSection={
                <Tooltip label={copied === 'url' ? 'Copied!' : 'Copy to clipboard'} position="top-end" withArrow>
                  <Button
                    variant="subtle"
                    color={copied === 'url' ? 'teal' : 'blue'}
                    onClick={() => copyToClipboard(sessionInfo.patientSessionLink || '', 'url')}
                    size="xs"
                  >
                    {copied === 'url' ? <IconCheck size={16} /> : <IconCopy size={16} />}
                  </Button>
                </Tooltip>
              }
              sx={{
                input: {
                  backgroundColor: '#F4F4F4',
                  border: 'none'
  
                },
              }}
            />
          </Stack>
          <Group position="apart" mt="xl">
            <Text size="sm" color={copied ? 'rgb(34 197 94)' : 'dimmed'}>
              {copied && 'Copied to clipboard!'}
            </Text>
            <Button onClick={handleClose} color="#3176CA" radius={'50px'}>Close</Button>
          </Group>
        </Stack>
      ) : (
        <form onSubmit={handleCreate}>
          <Stack spacing="md">
            <TextInput
              label={'Patient Name'}
              placeholder="John Doe"
              required
              value={patientName}
              radius={'8px'}
              sx={{
                '.mantine-InputWrapper-label': {
                  marginBottom: '12px !important',
                },
              }}
              onChange={(event) => setPatientName(event.currentTarget.value)}
            />
            <TextInput
              label={'Email Address'}
              placeholder="username@myemail.com"
              required
              value={email}
              type="email"
              onChange={(event) => setEmail(event.currentTarget.value)}
              radius={'8px'}
              sx={{
                '.mantine-InputWrapper-label': {
                  marginBottom: '12px !important',
                },
              }}
            />
            <PasswordInput
              label={'Password (Optional)'}
              placeholder="******"
              value={password}
              radius={'8px'}
              onChange={(event) => setPassword(event.currentTarget.value)}
              sx={{
                '.mantine-InputWrapper-label': {
                  marginBottom: '12px !important',
                },
              }}
            />

            <Group position="right" mt="md">
              <Button variant="default" onClick={handleClose} bg="#DCDCDC" disabled={isLoading} radius={'50px'}>
                Cancel
              </Button>
              {isLoading ? (
                <Button type="button" radius={'50px'} color="#3176CA" disabled>
                  Creating Zoom Meeting <Loader ml={10} variant="dots" size="sm" color="indigo" />;
                </Button>
              ) : (
                <Button type="submit" radius={'50px'} color="#3176CA">
                  Create
                </Button>
              )}
            </Group>
          </Stack>
        </form>
      )}
    </Modal>
  );
};

export default ZoomModal;
