/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Avatar, Box, Button, createStyles, FileButton, Group, Header as MantineHeader, Menu, Stack, Text, UnstyledButton } from '@mantine/core';
import { getReferenceString, ProfileResource } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { useMedplumContext } from '@medplum/react-hooks';
import { IconLogout, IconSettings, IconIndentIncrease, IconIndentDecrease, IconHeadset } from '@tabler/icons-react';
import React, { useEffect,  useState } from 'react';
import { HumanNameDisplay } from '../HumanNameDisplay/HumanNameDisplay';
import { ResourceAvatar } from '../ResourceAvatar/ResourceAvatar';
import { HeaderSearchInput } from './HeaderSearchInput';
import { useAppContext } from '../../../app/src/AppProvider';
import { showNotification } from '@mantine/notifications';
import  ConfirmBox  from '../../../app/src/dashboard/ConfirmBox';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  header: {
    boxShadow: `${theme.shadows.xs}`,
  },

  logoButton: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: '#fff',
      'svg': {
        stroke: '#000',
      }
    },
  },

  user: {
    padding: `${theme.spacing.xs}`,
    transition: 'background-color 100ms ease',

    '&:hover': {
      '& .mantine-bwc8t6': {
        color: '#000',
      }
    },
  },

  userName: {
    color: '#3176c9',
    fontWeight: 500,
    lineHeight: 1,
    marginRight: 3,

    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  userActive: {
    '& .mantine-bwc8t6': {
      color: '#000',
    }
  },
}));

export interface HeaderProps {
  pathname?: string;
  searchParams?: URLSearchParams;
  headerSearchDisabled?: boolean;
  logo: React.ReactNode;
  version?: string;
  navbarToggle: () => void;
}

export function Header(props: HeaderProps): JSX.Element {
  const context = useMedplumContext();
  const { medplum, profile } = context;
  const navigate = useNavigate();
  const logins = medplum.getLogins();
  const { classes, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { isSessionOn, setRedirectionUrl, isSummaryDetailsUpdated, setIsSessionStop,  isSessionState} = useAppContext();
  const [logoUrl, setlogoUrl] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [baseUrl, setBaseUrl] = useState('');
  const [practiceName, setPracticeName] = useState<string>();
  const [collapseIcon, setCollapseIcon] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    profile?.extension?.forEach((ext) => {
      if (ext.url === '/pmhs/StructureDefinition/practitioner-logo') {
        if(ext.valueAttachment?.data) {
          setlogoUrl(`data:${ext.valueAttachment?.contentType};base64,${ext.valueAttachment?.data}`);
        }
      } else if (ext.url === '/pmhs/StructureDefinition/practice-name') {
        setPracticeName(ext.valueString);
      }
    });
    const url = window.location.host;
    setBaseUrl(url === 'localhost:3000' ? 'http://localhost:8103' : 'https://pmhscribe.com:8103');

    // Collapse the navbar initially for mobile view
    if (window.matchMedia('(max-width: 768px)').matches) {
      setCollapseIcon(false);
      props.navbarToggle();
    }

  } , [profile]);

  //updating the logo
  const handleLogoUpdate = async (event: any) => {
    const token = await medplum.getAccessToken();
    const file = event?.[0];
    setFiles([file]);
  
    const formData = new FormData();
    formData.append('file', file);
  
    const url = `${baseUrl}/intg/update-practice-logo?practitionerId=${profile?.id}`;
    const headers = {
      Authorization: 'Bearer ' + token,
    };
  
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: formData
      });
  
      const data = await response.json();
      showNotification({ color: 'green', message: data?.message });
      // If you need to do something with the response data, do it here
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const collapseNavbar = (event: string) => {
    if(event === 'collapse') {
      setCollapseIcon(false);
    } else {
      setCollapseIcon(true);
    }
    props.navbarToggle();
  }

  const handleConfirm = () => {
    setIsConfirmOpen(false);
    window.location.href = '/session';
  }

  const handleStartNewSession = async () => {
    const currentUrl = window.location.pathname;
    const targetUrl = currentUrl === '/session';

    if (!(isSessionOn || isSummaryDetailsUpdated) || (isSessionState === 'paused')) {
      setRedirectionUrl(targetUrl);
      navigate('/session');
    }
    else {
      setIsSessionStop(true);
    }
  };

  const handleSignOutClick = async () => {
    const redirectionPath = `/signin`;
    if (isSessionOn || isSummaryDetailsUpdated) {
      if (isSessionState !== "" && isSessionState !== 'paused') {
        setRedirectionUrl(redirectionPath);
      }
      else {
        await medplum.signOut();
        navigate(redirectionPath);
      }
    }
    else {
      await medplum.signOut();
      navigate(redirectionPath);
    }
  };

  const handleAccountSettingsClick = () => {
    const redirectionPath = `/${getReferenceString(profile as ProfileResource)}/details`;
    if (!(isSessionOn || isSummaryDetailsUpdated)) {
      if (isSessionState === 'paused') {
        setRedirectionUrl(redirectionPath);
      } else {
        setRedirectionUrl(false);
        navigate(redirectionPath);
      }
    } else {
      setIsSessionStop(true);
      setRedirectionUrl(redirectionPath);
    }
  };

  const handleResourcesClick = () => {
    navigate('/knowledge-center');
  };

  return (
    <MantineHeader className={`header ${classes.header}`} height={70} p={8} style={{ zIndex: 101, justifyContent: 'space-between' }}>
      <Group position="apart" className='main-header'>
        <Group sx={{ alignItems: 'end' }} className='logo-header'>
          <Box mx="20px" onClick={() => navigate('/session')} sx={{ cursor: 'pointer'}}>
            {props.logo}
          </Box>
          {collapseIcon ? <IconIndentIncrease onClick={() => collapseNavbar('collapse')} style={{ cursor: 'pointer'}}/> : <IconIndentDecrease onClick={() => collapseNavbar('expande')} style={{ cursor: 'pointer'}}/>}
        </Group>
        <Group className='header-search'>
          {!props.headerSearchDisabled && (
            <HeaderSearchInput pathname={props.pathname} searchParams={props.searchParams} searchType={'patient'} />
          )}
        </Group>
        <Group className='header-menu-mob'>
          <Button
            variant="light"
            sx={{ backgroundColor: '#fba610', color: '#fff',
              '&:hover': {
                background: '#fba610',
              }
            }}
            onClick={handleStartNewSession}
          >
            Start New Session
          </Button>

          {isConfirmOpen && (
            <ConfirmBox
              isOpen={isConfirmOpen}
              onClose={() => setIsConfirmOpen(false)}
              onConfirm={() => handleConfirm()}
              content={
                'Please note that confirming this action will completely discard the unsaved changes. Would you like to proceed?'
              }
            />
          )}

        </Group>
        <Group className='header-menu-des'>
          <Button
            variant="light"
            sx={{ backgroundColor: '#fba610', color: '#fff',
              '&:hover': {
                background: '#fba610',
              }
            }}
            onClick={handleStartNewSession}
          >
            Start New Session
          </Button>
          <Menu
            width={260}
            shadow="xl"
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            opened={userMenuOpened}
            onClose={() => setUserMenuOpened(false)}
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                onClick={() => setUserMenuOpened((o) => !o)}
              >
                <Group spacing={7}>
                  <div style={{ textAlign: 'end'}}>
                    <Text size="sm">
                      {' '}
                      Welcome back, <HumanNameDisplay value={context.profile?.name?.[0] as HumanName} />!{' '}
                    </Text>
                    {!medplum.isSuperAdmin() && medplum.getProjectMembership()?.admin && (
                      <Text color="#3176C9" size="xs">
                        {practiceName}
                      </Text>
                    )}
                  </div>
                  {logoUrl ? (
                    <Avatar radius="xl" src={logoUrl} />
                  ) : (
                    files.length > 0 ? (
                      <Avatar radius="xl" src={URL.createObjectURL(files[0])} />
                    ) : (
                      <ResourceAvatar value={profile} radius={0} size={32} color="#3176C9" />
                    )
                  )}
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Stack align="center" p="xl">
                { logoUrl ? (<Avatar radius="xl" size={70} src={logoUrl} />
                ) : (
                  <FileButton onChange={handleLogoUpdate} accept="image/png,image/jpeg" multiple>
                  {(props) => 
                  files.length > 0 ? (
                    <Avatar radius="xl" src={URL.createObjectURL(files[0])} />
                  ) : (<ResourceAvatar {...props} size="xl" sx={{ cursor: 'pointer'}} radius={100} value={context.profile} color="#3176C9" />)}
                </FileButton>
                )}
                <HumanNameDisplay value={context.profile?.name?.[0] as HumanName} />
                <Text color="dimmed" size="xs">
                  {medplum.getActiveLogin()?.project.display}
                </Text>
              </Stack>
              {logins.length > 1 && <Menu.Divider />}
              {logins.map(
                (login) =>
                  login.profile.reference !== getReferenceString(context.profile as ProfileResource) && (
                    <Menu.Item
                      key={login.profile.reference}
                      onClick={() => {
                        medplum
                          .setActiveLogin(login)
                          .then(() => window.location.reload())
                          .catch(console.log);
                      }}
                    >
                      <Group>
                        <Avatar radius="xl" src={profile?.extension?.[0].valueAttachment?.data}/>
                        <div style={{ flex: 1 }}>
                          <Text size="sm" weight={500}>
                            {login.profile.display}
                          </Text>
                          <Text color="dimmed" size="xs">
                            {login.project.display}
                          </Text>
                        </div>
                      </Group>
                    </Menu.Item>
                  )
              )}
              <Menu.Divider />
              <Menu.Item
                icon={<IconSettings size={14} stroke={1.5} />}
                onClick={handleAccountSettingsClick}
              >
                Account settings
              </Menu.Item>
              <Menu.Item
                icon={<img src="../../../../img/icons/resource.svg" />}
                onClick={handleResourcesClick}
              >
                Knowledge Center
              </Menu.Item>
              <Menu.Item
                icon={<IconHeadset size={14} stroke={1.5} />}
                onClick={() => window.open("https://erx.solutions/index.php?module=ext/public/form&id=1", "_blank")}
              >
                User Support
              </Menu.Item>
              <Menu.Item
                icon={<IconLogout size={14} stroke={1.5} />}
                onClick={handleSignOutClick}
              >
                Sign out
              </Menu.Item>
              <Text size="xs" color="dimmed" align="center">
                {props.version}
              </Text>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </MantineHeader>
  );
};
