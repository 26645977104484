/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/rules-of-hooks */
import axios from './axios';
import { MedplumClient } from '@medplum/core';

export const createPatient = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/fhir/R4/Patient`, payload, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response;
};

export const getPatientDetails = async (medplum: MedplumClient, patientId: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(`/fhir/R4/Patient/${patientId}`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updatePatientDetails = async (medplum: any, payload: any, patientId: string) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/fhir/R4/Patient/${patientId}`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const createSession = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/create-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getAppointment = async (medplum: MedplumClient, appointmentId: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/fhir/R4/Appointment/${appointmentId}`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const inviteUserByAdmin = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/intg/inviteUserByAdmin`, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const npiResponse = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/pmhs/get-npi-details`, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const handleNonNpiReasonResponse = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();
  const response = await axios.post(`/pmhs/send-no-npi-reason`, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const generateSummary = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/generate-all-summaries-by-transcript`, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return response;
};

export const getSessionDetails = async (medplum: MedplumClient, id: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/intg/get-session/${id}`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return response.data;
};

export const updateNewSummary = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/update-soap`, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const submitNoteFeedback = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/save-feedback`, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const downloadSummaryDetails = async (medplum: MedplumClient, documentReferenceId: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/intg/download/${documentReferenceId}/pdf`, {
    headers: {
      Authorization: 'Bearer ' + token,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    responseType: 'blob',
  });
  return response;
};

export const sendDetailsOnEmail = async (medplum: MedplumClient, formData: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/send-email`, JSON.stringify(formData), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return response.data;
};

export const createNewSession = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/save-patient-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};


export const createTemplate = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/create-update-note-template`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updateTemplate = async (medplum: any, payload: any, compId: string) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/create-update-note-template`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getTemplateDetails = async (medplum: MedplumClient, id: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/fhir/R4/Composition/${id}`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const deleteTemplate = async (medplum: MedplumClient, id: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.delete(`/fhir/R4/Composition/${id}`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getTemplateList = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/fhir/R4/Composition`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getTemplateListAccordingToRole = async (medplum: MedplumClient, prctitionerRole: string) => {
  const token = await medplum.getAccessToken();

  const url = `/intg/get-templates?role=${prctitionerRole}`;

  const response = await axios.get(url, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getPractitionerDetails = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/intg/get-practitioner-details`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updateTranscript = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/intg/update-notes-by-transcript`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  return response.data;
};

export const regenerateTranscript = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/intg/regenerate-note`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const fetchICDCodesDataBySearch = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/intg/diagnoses-icd10-list`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getQuestionnaire = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/pmhs/register/Practitioner-Signup`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updateQuestionnaireResponse = async (medplum: any, id: string, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/fhir/R4/QuestionnaireResponse/${id}`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updatePractitionerDetails = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/intg/update-signup-resources `, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getQuestionnaireList = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/fhir/R4/Questionnaire?_count=20&_offset=0&_sort=-_lastUpdated&_total=accurate&name=Patient Assessment`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getPractitionerRoleList = async (medplum: any, count?: any, offset?: any, search?: any) => {
  const token = await medplum.getAccessToken();
  let queryParameters = '';
  let sortingParameters = '';
  if (search?.filters) {
    search.filters.forEach((filter: { code: any; value: any }) => {
      queryParameters += `&${filter.code}=${filter.value}`;
    });
  }
  if (search?.sortRules) {
    search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
      if (filter.descending) {
        sortingParameters += `&_sort=-${filter.code}`;
      } else {
        sortingParameters += `&_sort=${filter.code}`;
      }
    });
  }
  try {
    const response = await axios.get(
      `/fhir/R4/PractitionerRole?_count=${count}&_offset=${offset}${sortingParameters}&_sort=-_lastUpdated&_total=accurate${queryParameters}`,
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createQuestionnaireResponse = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/fhir/R4/QuestionnaireResponse`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const fetchPatientDetails = async (medplum: any, query: string) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(
      `/fhir/R4/$graphql`,
      {
        query: query,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error occurred:', error);
    throw error;
  }
};

export const getMedicationManagement = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const titles = ['Medication Management', 'Psychotherapy'];
  const titleParam = titles.join(',');

  const response = await axios.get(`/fhir/R4/Composition?_count=20&_fields=id,type,title,_lastUpdated&_offset=0&_sort=-_lastUpdated&title=${titleParam}`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getFeedbackList = async (medplum: MedplumClient, category: string, offset: number, id?: string, searchType?: string, startDate?: string, endDate?: string) => {
  const token = await medplum.getAccessToken();
  let actor = '';
  if (id) {
    if (searchType === 'practitioner') {
      actor = `&subject=Practitioner/${id}`;
    } else if (searchType === 'patient') {
      actor = `&identifier=Patient/${id}`;
    }
  }

  let url = `/intg/get-feedback-list?_count=20${actor}&_offset=${offset}&_sort=-_lastUpdated`;
  if (category !== 'Rating') {
    url += `&category=${category}`;
  }

  if (startDate && endDate) {
    const formattedStartDate = encodeURIComponent(`sa${startDate}`);
    const formattedEndDate = encodeURIComponent(`eb${endDate}`);

    url += `&authored=${formattedStartDate}&authored=${formattedEndDate}`;
  }

  const response = await axios.get(url, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });

  return response.data;
};

export const getInitialPsychiatric = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const titles = ['Initial Evaluation'];
  const titleParam = titles.join(',');

  const response = await axios.get(`/fhir/R4/Composition?_count=20&_fields=id,type,title,_lastUpdated&_offset=0&_sort=-_lastUpdated&title=${titleParam}`, {
    headers: {
      Accept: 'application/fhir+json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getGeneratePatientAssessment = async (medplum: MedplumClient, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/generate-patient-assessment-by-ai`, payload, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updateNote = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/intg/update-note-with-user-input`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const activateAccount = async (payload: any) => {

  const response = await axios.post(`/pmhs/activate-account`, payload, {
    headers: {
      Accept: 'application/json',
    },
  });
  return response.data;
};

export const createStripeSsession = async (payload: any) => {
  try {
    const response = await axios.post(`/pmhs/create-stripe-session`, payload, {
      headers: {
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const restrictRepeatPayment = async (payload: any) => {
  try {
    const response = await axios.post(`/pmhs/restrict-repeat-payment-invited-user`, payload, {
      headers: {
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const saveUnknownPatientSession = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/save-unknown-patient-session`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getStripeSession = async (payload: any) => {
  try {
    const response = await axios.post(`/pmhs/get-stripe-session`, payload, {
      headers: {
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getStripeDetails = async () => {
  try {
    const response = await axios.get(`pmhs/get-stripe-config`, {
      headers: {
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getStripeRenewSession = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/intg/create-stripe-renew-session`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const completeUnknownPatientSession = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/intg/complete-unknown-patient-session`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getOtherICDCode = async (query: string, medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`/intg/get-other-icd10?search=${query}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // or handle the error as you see fit
  }
};

export const updatePractitionerResponse = async (medplum: any, id: string, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/fhir/R4/Practitioner/${id}`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getPractitionerRole = async (medplum: any, id: string) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/fhir/R4/PractitionerRole?practitioner=Practitioner/${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const updatePractitionerRole = async (medplum: any, payload: any) => {
  const id = payload?.id;
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/fhir/R4/PractitionerRole/${id}`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const deletePractitionerResponse = async (medplum: any, id: string) => {
  const token = await medplum.getAccessToken();

  const response = await axios.delete(`intg/delete-practitioner-all-data?practitionerId=${id}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const deletePatientResponse = async (medplum: any, id: string) => {
  const token = await medplum.getAccessToken();

  const response = await axios.delete(`/intg/delete-patient-all-data?patientId=${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const archivePatientResponse = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/archive-patient`, payload, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const archiveSessionResponse = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  //url path is delete-session but it is used to archive session
  const response = await axios.post(`/intg/delete-session`, payload, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const archivePractitionerResponse = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.post(`/intg/archive-practitioner`, payload, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const deleteSessionAndNotes = async (medplum: any, id: string) => {
  const token = await medplum.getAccessToken();

  const response = await axios.delete(`/intg/delete-session-and-notes/${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const checkSubscriptionExpired = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();

  const response = await axios.get(`/intg/check-subscription-expired`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getPatientList = async (medplum: any, count?: any, offset?: any, search?: any, activeStatus?: any, patientName?: string) => {
  const token = await medplum.getAccessToken();
  // Initialize your query parameters
  let queryParameters = '';
  let sortingParameters = '';
  // Loop through the filters array to build the query string
  if (search.filters) {
    search.filters.forEach((filter: { code: any; value: any }) => {
      queryParameters += `&${filter.code}=${filter.value}`;
    });
  }
  if (search?.sortRules) {
    search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
      if (filter.descending) {
        sortingParameters += `&_sort=-${filter.code}`;
      } else {
        sortingParameters += `&_sort=${filter.code}`;
      }
    });
  }
  try {
    const response = await axios.get(
      `/intg/get-patient-list?_count=${count}&_offset=${offset}${sortingParameters}&_sort=-_lastUpdated&_total=accurate${queryParameters}&active=${activeStatus}${patientName ? `&name:contains=${patientName}` : ''}`,
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
          'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPractitionerList = async (medplum: any, count?: any, offset?: any, search?: any, practitionerActiveStatus?: string, practitionerName?: string) => {
  const token = await medplum.getAccessToken();
  // Initialize your query parameters
  let queryParameters = '';
  let sortingParameters = '';

  // Loop through the filters array to build the query string
  if (search.filters) {
    search.filters.forEach((filter: { code: any; value: any }) => {
      queryParameters += `&${filter.code}=${filter.value}`;
    });
  }

  if (search?.sortRules) {
    search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
      if (filter.descending) {
        sortingParameters += `&_sort=-${filter.code}`;
      } else {
        sortingParameters += `&_sort=${filter.code}`;
      }
    });
  }
  try {
    const response = await axios.get(
      `/intg/get-practitioner-list?_count=${count}&_offset=${offset}${sortingParameters}&_sort=-_lastUpdated&_total=accurate${queryParameters}&active=${practitionerActiveStatus}${practitionerName ? `&name:contains=${practitionerName}` : ''}`,
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPatientSessionsResp = async (medplum: any, count?: any, offset?: any, search?: any, unknownIdentifier?: any, status?: any, patientId?: string) => {
  const token = await medplum.getAccessToken();
  const identifierParam = unknownIdentifier ? 'identifier=unknown' : 'identifier:not=unknown';
  const actor = patientId ? `&actor=Patient/${patientId}` : '';
  // Initialize your query parameters
  let queryParameters = '';
  let sortingParameters = '';
  // Loop through the filters array to build the query string
  if (search?.filters) {
    search.filters.forEach((filter: { code: any; value: any }) => {
      queryParameters += `&${filter.code}=${filter.value}`;
    });
  }
  if (search?.sortRules) {
    search.sortRules.forEach((filter: { code: string; descending: boolean }) => {
      if (filter.descending) {
        sortingParameters += `&_sort=-${filter.code}`;
      } else {
        sortingParameters += `&_sort=${filter.code}`;
      }
    });
  }

  try {
    const response = await axios.get(
      `/intg/get-patient-sessions?${identifierParam}${actor}&_count=${count}&_offset=${offset}${sortingParameters}&_sort=-_lastUpdated&_total=accurate${queryParameters}&${status !== undefined ? `&status=${status}` : ''}`,
      {
        headers: {
          Accept: 'application/fhir+json',
          Authorization: 'Bearer ' + token,
          'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const saveTranscripts = async (medplum: any, payload: any) => {
  const token = await medplum.getAccessToken();

  const response = await axios.put(`/intg/update-transcript`, payload, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
};

export const getCurrentSubscriptionPlan = async (medplum: any, userid: string) => {
  const token = await medplum.getAccessToken();
  const response = await axios.get(`/intg/get-current-plan/${userid}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });
  return response.data;
}

export const registerOnPayment = async (payload: any) => {
  try {
    const response = await axios.post(`/pmhs/register-on-payment`, payload, {
      headers: {
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getZoomToken = async (payload: any, medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/intg/get-zoom-token`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const checkIsZoomEnabled = async (medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.get(`/intg/is-zoom-enabled`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updatePatientResource = async (payload: any, medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/intg/update-patient-resource`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addPaymentMethod = async (payload: any, medplum: MedplumClient) => {
  const token = await medplum.getAccessToken();
  try {
    const response = await axios.post(`/intg/add-payment-method`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};