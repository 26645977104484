/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { TextInput, Button, Box, Group, Checkbox, Title, PasswordInput, Radio, Select, Loader, Tooltip } from '@mantine/core';
import { AttachmentInput } from '../questionnaire/AttachmentInput';
import { CheckboxFormSection, QuestionnaireItemType, useMedplum, useResource } from '@medplum/react';
import { getPractitionerRole, getCurrentSubscriptionPlan, updatePractitionerDetails, updatePractitionerResponse } from '../utils/util';
import { useNavigate, useParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { cleanResource } from './utils';
import { normalizeErrorString } from '@medplum/core';
import { formatValue } from '../utils';
import { ResourceType } from '@medplum/fhirtypes';
import { DateInput } from '@mantine/dates';
import { format } from 'date-fns';
import { IconInfoCircle } from '@tabler/icons-react';

interface QuestionnaireFormProps {
  data: any;
}

export const practitionerMap: Record<string, string> = {
  "prescriber": "Psychiatry",
  "therapist": "Counselor",
  "psychological testing": "Psychological Testing"
};

const reversePractitionerMap: Record<string, string> = Object.fromEntries(
  Object.entries(practitionerMap).map(([key, value]) => [value, key])
);

const QuestionnaireForm: React.FC<QuestionnaireFormProps> = ({ data }) => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>(data);
  const { id } = useParams() as { id: string };
  const [radioValue, setRadioValue] = useState<string>('true');
  const [practitionerData, setPractitionerData] = useState<any>();
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const { resourceType } = useParams() as { resourceType: ResourceType; };
  const resource: any = useResource({ reference: resourceType + '/' + id });
  const [currentRole, setCurrentRole] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedExpiryDate, setSelectedExpiryDate] = useState<Date | null>(null);
  useEffect(() => {
    medplum.readResource('Practitioner', id).then((res: any) => {
      const active = res?.active ? 'true' : 'false';
      setRadioValue(active);
      setPractitionerData(res);
    }).catch((error: any) => {
      console.error('Error reading resource:', error);
      showNotification({ color: 'red', message: 'Failed' });
    });
  }, []);

  useEffect(() => {
    if (resource?.resourceType === 'Practitioner' && resource.id) {
      const practitionerId = resource.id;
      getPractitionerRole(medplum, practitionerId)
        .then((response: any) => {
          const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
          const mappedRole = practitionerMap[role.toLowerCase()] || role;
          setCurrentRole(mappedRole);
        })
        .catch((error: any) => {
          console.error('Failed to get practitioner role:', error);
        });
    }
  }, [resource, medplum]);

  useEffect(() => {
    if (resource?.resourceType === 'Practitioner') {
      const identifier = resource.identifier?.find((id: { system: string; }) => id.system === 'http://user-id');
      const userId = identifier?.value;
      if (userId) {
        getCurrentSubscriptionPlan(medplum, userId)
          .then((response: any) => {
            if (response) setSelectedExpiryDate(new Date(response.endDate));
          })
          .catch((error: any) => {
            console.error('Failed to get subscription plan', error);
          });
      }
    }
  }, [resource, medplum]);

  const handleChange = (questionLinkId: string, value: any): any => {
    setFormData((prevData: any) => {
      const updatedData = { ...prevData };

      const findItem = (items: any[]): any => {
        for (const item of items) {
          if (item.linkId === questionLinkId) {
            if (item.answer) {
              const answer = item.answer[0] || {};

              // Check the type of the answer and update accordingly
              if ('valueString' in value) {
                answer.valueString = value.valueString;
              } else if ('valueAttachment' in value) {
                answer.valueAttachment = value.valueAttachment;
              } else if ('valueBoolean' in value) {
                answer.valueBoolean = value.valueBoolean;
              } else if ('valueDate' in value) {
                answer.valueDate = value.valueDate;
              }

              item.answer[0] = answer;

              break;
            } else { // If no answer is present, create a new answer
              item.answer = [value];
              break;
            }
            break;
          }

          // Check subitems from the list
          if (item.item) {
            findItem(item.item);
          }
        }
      };

      if (updatedData.item) {
        findItem(updatedData.item);
      }

      return updatedData;
    });
  };

  const getKeyByValue = (value: string): string | undefined => {
    const practitionerTypes = {
      "44652006": "Prescriber",
      "65656005": "Therapist",
      "275356005": "Psychological Testing"
    };
    return Object.entries(practitionerTypes).find(([key, val]) => val === value)?.[0];
  };

  const handleRoleChange = (value: string | null) => {
    if (value !== null) {
      setCurrentRole(value);
    }
  };

  const handleSubmit = (): any => {
    setLoading(true);
    if (medplum.isSuperAdmin() && !currentRole) {
      setLoading(false);
      showNotification({ color: 'red', message: 'Please select a role' });
      return;
    }
    const keys = ['compartment', 'project'];
    const updatedFormData = { ...formData };

    // to remove extra fields from resources meta
    Object.keys(updatedFormData.meta).forEach((key) => {
      if (!keys.includes(key)) {
        delete updatedFormData.meta[key];
      }
    });

    medplum.updateResource(cleanResource(updatedFormData))
      .then((reasponse: any) => {

        interface Payload {
          questionnaireResponseId: any;
          practitionerId: string;
          subscriptionExpiryDate?: string;
          role?: any;
          isActive?: boolean;
        }

        const payload: Payload = {
          questionnaireResponseId: reasponse.id,
          practitionerId: id,
        }

        if (medplum.isSuperAdmin()) {
          let formattedExpiryDate = '';
          if (selectedExpiryDate) {
            formattedExpiryDate = format(selectedExpiryDate, 'MM/dd/yyyy');
          }
          payload.subscriptionExpiryDate = formattedExpiryDate;
          const codeValue = getKeyByValue(currentRole || '');

          const originalRole = reversePractitionerMap[currentRole || ''] || '';
          const capitalizedOriginalRole = originalRole.charAt(0).toUpperCase() + originalRole.slice(1);
          payload.role = [
            {
              coding: [
                {
                  code: codeValue,
                  display: capitalizedOriginalRole,
                  system: "http://terminology.hl7.org/CodeSystem/practitioner-role",
                },
              ],
            },
          ];

          medplum.isSuperAdmin() && (
            payload.isActive = radioValue === 'true' ? true : false
          );
        }

        updatePractitionerDetails(medplum, payload)
          .then((res: any) => {
            console.log(res);
            const persoanlData = extractData(updatedFormData);
            medplum.isSuperAdmin() && (
              practitionerData.active = radioValue === 'true' ? true : false
            );
            practitionerData.name = [{
              family: persoanlData.lastName,
              given: [persoanlData.firstName]
            }];

            //check and update practive name in practitioner resource
            const hasPracticeNameExtension = practitionerData?.extension?.some((ext: any) => ext.url === '/pmhs/StructureDefinition/practice-name');
            if (hasPracticeNameExtension) {
              practitionerData.extension.forEach((ext: any) => {
                if (ext.url === '/pmhs/StructureDefinition/practice-name') {
                  ext.valueString = persoanlData.practiceName;
                }
              });
            } else {
              practitionerData.extension.push({
                url: '/pmhs/StructureDefinition/practice-name',
                valueString: persoanlData.practiceName
              });
            }

            //check and update practive logo in practitioner resource
            const hasLogoExtension = practitionerData?.extension?.some((ext: any) => ext.url === '/pmhs/StructureDefinition/practitioner-logo');
            if (hasLogoExtension) {
              practitionerData.extension.forEach((ext: any) => {
                if (ext.url === '/pmhs/StructureDefinition/practitioner-logo') {
                  ext.valueAttachment = persoanlData.logo?.valueAttachment
                }
              });
            } else {
              if (persoanlData.logo?.valueAttachment) {
                practitionerData.extension.push({
                  url: '/pmhs/StructureDefinition/practitioner-logo',
                  valueAttachment: persoanlData.logo?.valueAttachment
                });
              }
            }

            if (medplum.isSuperAdmin()) {
              if (practitionerData.active) {
                practitionerData.extension = practitionerData.extension?.filter((ext: any) => ext.url !== 'http://archive-practitioner');
              } else {
                practitionerData.extension = practitionerData.extension || [];
                practitionerData.extension.push({
                  url: 'http://archive-practitioner',
                  valueBoolean: true
                });
              }
            }

            practitionerData.address = [{
              line: [persoanlData.streetAddress, persoanlData.streetAddressLine2],
              city: persoanlData.city,
              state: persoanlData.state,
              postalCode: persoanlData.postalCode
            }],
              practitionerData.telecom = [{
                system: 'phone',
                value: persoanlData.phoneNumber
              }, {
                system: 'email',
                value: persoanlData.email
              }]


            updatePractitionerResponse(medplum, id, practitionerData).then((res: any) => {
              showNotification({ color: 'green', message: 'Success' });
              setLoading(false);
              window.location.href = '/Practitioner/' + id + '/details'; 
            }).catch((error: any) => {
              setLoading(false);
              console.error('Error updating resource:', error);
              showNotification({ color: 'red', message: 'Failed' });
            });
          })
          .catch((error: any) => {
            setLoading(false);
            console.error('Error updating resource:', error);
            showNotification({ color: 'red', message: 'Failed' });
          });
      })
      .catch((err) => {
        setLoading(false);
        showNotification({ color: 'red', message: normalizeErrorString(err) });
      });

  };


  const extractData = (response: any) => {
    const findGroup = (linkId: string) => response.item.find((group: { linkId: string; }) => group.linkId === linkId);
    const findItem = (group: any, linkId: string) => group?.item.find((item: { linkId: string; }) => item.linkId === linkId);
    const extractAnswer = (item: any) => item?.answer?.[0]?.valueString || "";
    const extractLogo = (item: any) => item?.answer?.[0] || "";

    const personalInfoGroup = findGroup("g5");
    const addressGroup = findGroup("g11");
    const contactGroup = findGroup("g29");

    const firstName = extractAnswer(findItem(personalInfoGroup, "Q1"));
    const lastName = extractAnswer(findItem(personalInfoGroup, "Q2"));

    const logo = extractLogo(findItem(addressGroup, "Q5"));
    const practiceName = extractAnswer(findItem(addressGroup, "Q6"));
    const streetAddress = extractAnswer(findItem(addressGroup, "Q7"));
    const streetAddressLine2 = extractAnswer(findItem(addressGroup, "Q8"));
    const city = extractAnswer(findItem(addressGroup, "Q9"));
    const state = extractAnswer(findItem(addressGroup, "Q10"));
    const postalCode = extractAnswer(findItem(addressGroup, "Q11"));

    const phoneNumber = extractAnswer(findItem(contactGroup, "Q19"));
    const email = extractAnswer(findItem(contactGroup, "Q20"));

    return { firstName, lastName, logo, practiceName, streetAddress, streetAddressLine2, city, state, postalCode, phoneNumber, email };
  };

  const formatAndValidateNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength <= 3) return phoneNumber;
    if (phoneNumberLength <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    if (phoneNumberLength <= 10) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>, field: string) => {
    const target = e.currentTarget;
    let inputValue = target.value;

    if (field === 'License Number') {
      inputValue = inputValue.replace(/[^a-zA-Z0-9-]/g, '');
      target.value = inputValue;
    }

    // If the field is 'NPI 1' or 'NPI 2', replace non-numeric characters immediately
    if (field === 'NPI 1' || field === 'NPI 2') {
      inputValue = inputValue.replace(/[^0-9]/g, '');
      target.value = inputValue;
    }

    if (inputValue.length > 10 && (field === 'NPI 1' || field === 'NPI 2')) {
      target.value = inputValue.slice(0, 10);
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
    } else if (inputValue.length < 10 && (field === 'NPI 1' || field === 'NPI 2')) {
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: `${field} should be 10 digits long.` }));
      setValues((prevValues: any) => ({ ...prevValues, [field]: inputValue }));
    }

    // Format and validate phone numbers or fax numbers or personal cell numbers
    if (field === 'Practice Phone' || field === 'Practice Fax' || field === 'Personal Cell') {
      inputValue = formatAndValidateNumber(inputValue);
      target.value = inputValue;

      // Validation for number of digits (should be 10 digits)
      if (inputValue.replace(/-/g, '').length !== 10) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [field]: `${field} should be 10 digits long.`,
        }));
      } else {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
      }
      setValues((prevValues: any) => ({ ...prevValues, [field]: inputValue }));
    }

    else if (field === 'Practice Email\n' || field === 'Personal Email') {
      // Regular expression for email validation
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(inputValue)) {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: `${field} is not valid.` }));
      } else {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
      }
      setValues((prevValues: any) => ({ ...prevValues, [field]: inputValue }));
    } else {
      setValues((prevValues: any) => ({ ...prevValues, [field]: inputValue }));
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
    }
  };

  const handleCheckValidity = (e: any, field: string) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    const formattedValue = formatValue(e.target.value);

    if (formattedValue.length === 10 || formattedValue.length === 0) {
      setValues((prevValues: any) => ({ ...prevValues, [field]: formattedValue }));
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
    } else {
      setValues((prevValues: any) => ({ ...prevValues, [field]: formattedValue }));
    }
  };

  const renderQuestion = (question: (typeof data)['item'][0]): JSX.Element | null => {
    if (question.definition === QuestionnaireItemType.group) {
      return (
        <Box key={question.id} mb="lg">
          {(question.linkId !== "g33" || question.text !== 'Create Your Login') && (
            <Title order={4} my="lg">
              {question.text}
            </Title>
          )}
          {question.item.map((subQuestion: any) => renderQuestion(subQuestion))}
        </Box>
      );
    } else {
      const answer: any = question.answer?.[0];
      if (question.definition === QuestionnaireItemType.string) {
        return (
          <Box key={question.linkId} mb="sm">
            {question.text === 'Password' ? (
              <PasswordInput
                placeholder="Password"
                label={question.text}
                value={answer?.valueString || ''}
                onChange={(event) => handleChange(question.linkId, { valueString: event.target.value })}
              />
            ) : (
              <>
                <TextInput
                  label={question.text}
                  value={values[question.text] || answer?.valueString || ''}
                  onChange={(event) => handleChange(question.linkId, { valueString: event.target.value })}
                  onInput={
                    question.text === 'Practice TIN/EIN'
                      ? (event) => handleCheckValidity(event, question.text)
                      : question.text === 'NPI 1' ||
                        question.text === 'NPI 2' ||
                        question.text === 'License Number' ||
                        question.text === 'Practice Phone' ||
                        question.text === 'Practice Fax' ||
                        question.text === 'Practice Email\n' ||
                        question.text === 'Personal Cell' ||
                        question.text === 'Personal Email'
                        ? (event) => handleInput(event, question.text)
                        : () => { }
                  }
                />
                {errors[question.text] && <span className="error">{errors[question.text]}</span>}
              </>
            )}
          </Box>
        );
      } else if (question.definition === QuestionnaireItemType.attachment) {
        return (
          <Box key={question.linkId} my="sm">
            <label className="font-dark">{question.text}</label>
            <AttachmentInput
              name={question.text}
              defaultValue={answer?.valueAttachment}
              onChange={(value) => handleChange(question.linkId, { valueAttachment: value })}
            />
          </Box>
        );
      } else if (question.definition === QuestionnaireItemType.boolean) {
        return (
          <CheckboxFormSection key={question.id} title={question.text} htmlFor={question.id}>
            <Checkbox
              id={question.linkId}
              name={question.text}
              defaultChecked={answer?.valueBoolean || undefined}
              onChange={(event) => handleChange(question.linkId, { valueBoolean: event.target.value })}
            />
          </CheckboxFormSection>
        );
      } else if (question.definition === QuestionnaireItemType.date) {
        return (
          <Box key={question.linkId} my="sm">
            <label className="font-dark">{question.text}</label>
            <TextInput
              type="date"
              id={question.linkId}
              name={question.text}
              defaultValue={answer?.valueDate || ''}
              onChange={(e) => handleChange(question.linkId, { valueDate: e.target.value })}
            />
          </Box>
        );
      } else if (question.definition === QuestionnaireItemType.integer) {
        return (
          <Box key={question.linkId} my="sm">
            <label className="font-dark">{question.text}</label>
            <TextInput
              type="number"
              id={question.linkId}
              name={question.text}
              defaultValue={answer?.valueDate || ''}
              onChange={(e) => handleChange(question.linkId, { valueDate: e.target.value })}
            />
          </Box>
        );
      }
    }

    return null;
  };

  const handleDateChange = (value: Date | null) => {
    setSelectedExpiryDate(value);
  };

  if (!data) {
    return null;
  }

  return (
    <Box p="lg">
      {data?.item.map((question: any) => renderQuestion(question))}
      {medplum.isSuperAdmin() && (
        <Radio.Group
          value={radioValue}
          onChange={setRadioValue}
          name="active"
          label="Account Status"
        >
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <Radio value="true" label="Active" />
            <Radio value="false" label="Inactive" ml={10} />
          </div>
        </Radio.Group>
      )}

      {medplum.isSuperAdmin() && (
        <Group sx={{ display: 'block' }}>
          <Title order={4} my="lg">
            Practitioner Role
          </Title>
          <Select
            value={currentRole}
            placeholder="Please select a practitioner role"
            data={['Psychiatry', 'Counselor']}
            onChange={handleRoleChange}
          />
        </Group>
      )}

      {medplum.isSuperAdmin() && (
        <Group sx={{ display: 'block' }}>
          <Title order={4} my="lg">
            Extend Subscription Expiry
            <Tooltip
              label="The Subscription Date or Override Date whichever is greater will be used to access the app."
              withArrow
              position="right"
              sx={{ backgroundColor: '#eaeced6e', color: '#000', fontSize: '12px', fontWeight: 'normal' }}
            >
              <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                <IconInfoCircle size={16} />
              </span>
            </Tooltip>
          </Title>
          <DateInput
            className='dateInput'
            onChange={handleDateChange}
            maw={400}
            minDate={new Date()}
            defaultValue={new Date()}
            value={selectedExpiryDate}
            style={{ marginBottom: '20px' }}
            placeholder="MM-DD-YYYY"
            valueFormat="MM-DD-YYYY"
          />
        </Group>
      )}

      <Group position="right" mt="lg">
        <Button className="btn-cancel" px={'lg'} style={{ borderRadius: '25px' }} onClick={() => navigate('/Practitioner/' + id + '/details')} >
          Cancel
        </Button>

        <Button
          className="btn-success"
          px={'lg'}
          style={{ borderRadius: '25px' }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <Loader
              size="sm"
              color="white"
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </Group>
    </Box>
  );
};

export default QuestionnaireForm;
