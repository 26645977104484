/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Center, Pagination, Table, Text, Tooltip, createStyles } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { useMedplum } from '@medplum/react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconTrash } from '@tabler/icons-react';
import ConfirmBox from '../patients/ConfirmBox';
import { deleteSessionAndNotes } from '../utils/util';
import { toast } from 'react-toastify';

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  tr: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

export function Sessions(): JSX.Element | null {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const resourceId = useParams().id;
  const [appointmentResponse, setAppointmentResponse] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  const navigate = useNavigate();
  const resource = window.location.pathname.includes('Practitioner') ? 'Practitioner' : 'Patient';
  const [deletingUserId, setDeletingUserId] = useState<string>('');
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [isDeletingLoader, setIsDeletingLoader] = useState(false);


  const getSessionsList = async () => {
    const token = await medplum.getAccessToken();
    const offset = (currentPage - 1) * perPage;
    let resourceType;
    if (resource === 'Practitioner') {
      resourceType = 'actor=Practitioner/' + resourceId;
    } else {
      resourceType = 'patient=' + resourceId;
    }
    try {
      const response = await axios.get(
        `intg/get-patient-sessions?${resourceType}&_offset=${offset}&identifier:not=unknown&_count=20&_sort=-_lastUpdated&_total=accurate&status=checked-in`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: 'Bearer ' + token,
            'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );
      return response.data.entry;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    getSessionsList()
      .then((response: any) => {
        setAppointmentResponse(response);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  }, [currentPage]);

  const paginate = (data: any) => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;

    return data.slice(startIndex, endIndex);
  };

  const handleRowClick = (row: any) => {
    if (row.id) {
      navigate(`/Appointment/${row.id}/details`);
    }
  };

  const handleDeleteIconClick = (resource: any) => {
    setDeletingUserId(resource.id);
    setShowConfirmBox(true);
  };

  const handleConfirmDelete = async () => {
    debugger;
    setIsDeletingLoader(true);
    await deleteSessionAndNotes(medplum, deletingUserId)
      .then((response) => {
        if (response) {
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setIsDeletingLoader(false);
          setShowConfirmBox(false);
          //call getAppointment to refresh the list
          getSessionsList()
            .then((response: any) => {
              setAppointmentResponse(response);
            })
            .catch((error: any) => {
              console.error('Error fetching sessions:', error);
            });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        toast.error(`Failed to delete session`), {
          position: toast.POSITION.TOP_RIGHT
        }
      });
    setIsDeletingLoader(false);
    setShowConfirmBox(false);

  };

  return (
    <div className="resource-panel">
      <div className="resource-list">
        {resource === 'Practitioner' ? (
          <div>
            <Table>
              <thead>
                <tr>
                  <th className="table-heading">Session Date & Time</th>
                  <th className="table-heading">Session Description</th>
                  <th className="table-heading">Patient</th>
                  {medplum.isSuperAdmin() && <th className="table-heading">Tokens/Cost</th>}
                  <th className="table-heading" style={{ textAlign: 'center' }}>Actions</th>
                  <th className="table-heading"></th>
                </tr>
              </thead>
              <tbody>
                {appointmentResponse.length > 0 ? (
                  paginate(appointmentResponse)?.map((data: any, index: number) => (
                    <tr key={index} className={classes.tr} onClick={() => handleRowClick(data.resource)}>
                      <td>{data.resource?.start ? data?.resource?.start : ''}</td>
                      <td>{data.resource?.description || ''}</td>
                      {
                        //show patient name from data.resource
                        data.resource?.participant?.map((participant: any) => {
                          if (participant.actor?.reference?.includes('Patient')) {
                            return <td key={participant.actor?.reference}>{participant.actor?.display}</td>;
                          }
                          return '';
                        })
                      }
                      {medplum.isSuperAdmin() &&
                        <td>
                          {data.resource?.numberOfTokens || ''}
                          {data.resource?.numberOfTokens && data.resource?.indicativeCost ? ' / ' : ''}
                          ${Number(data.resource?.indicativeCost).toFixed(5) || ''}
                        </td>}
                      {medplum.isSuperAdmin() && resource === 'Practitioner' &&
                        <td className="actions" style={{ textAlign: 'center' }}>
                          {(
                            <Tooltip
                              label="Delete Session"
                              withArrow
                              position="top"
                              sx={{ fontSize: '12px', marginTop: '1px' }}
                            >
                              <IconTrash
                                size={18}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteIconClick(data.resource);
                                }}
                              />
                            </Tooltip>
                          )}
                        </td>
                      }
                      {medplum.isSuperAdmin() && <td></td>}
                    </tr>
                  ))
                ) : (
                  <tr key={0} className={classes.tr}>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                      <Text>No Data</Text>
                    </td>
                  </tr>
                )}

                <ConfirmBox
                  isOpen={showConfirmBox}
                  onClose={() => setShowConfirmBox(false)}
                  onConfirm={() => {
                    handleConfirmDelete();
                  }}
                  confirm={'Confirm'}
                  cancel="Cancel"
                  message={`Are you sure you want to delete this session?`}
                  isLoader={isDeletingLoader} additionalText={''} patient={undefined} />
              </tbody>
            </Table>
          </div>
        ) : (
          <div>
            <Table>
              <thead>
                <tr>
                  <th className="table-heading">Session</th>
                  <th className="table-heading">Date & Time</th>
                  {medplum.isSuperAdmin() && <th className="table-heading">Tokens/Cost</th>}
                </tr>
              </thead>
              <tbody>
                {appointmentResponse.length > 0 ? (
                  paginate(appointmentResponse)?.map((data: any, index: number) => (
                    <tr key={index} className={classes.tr} onClick={() => handleRowClick(data.resource)}>
                      <td>{data.resource?.description || ''}</td>
                      <td>{data.resource?.start ? data?.resource?.start : ''}</td>
                      {medplum.isSuperAdmin() && <td>
                        {data.resource?.numberOfTokens || ''}
                        {data.resource?.numberOfTokens && data.resource?.indicativeCost ? ' / ' : ''}
                        ${Number(data.resource?.indicativeCost).toFixed(5) || ''}
                      </td>}
                    </tr>
                  ))
                ) : (
                  <tr key={0} className={classes.tr}>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                      <Text>No Data</Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}

        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          <Pagination
            className="pagination"
            value={currentPage}
            total={Math.ceil(appointmentResponse.length / perPage)}
            onChange={setCurrentPage}
          />
        </Center>
      </div>
    </div >

  );
}
